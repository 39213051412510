import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import styled from 'styled-components';

Chart.register(ArcElement, Tooltip, Legend);

const RadialBarChart = ({ percentage, colorDut, text, add }) => {
  const data = {
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: [colorDut, '#E0E0E0'], // Цвета для заполненной и незаполненной части
        borderWidth: 0, // Убираем границы
        borderRadius: 10,
      },
    ],
  };

const options = {
  cutout: '70%', // Размер внутреннего радиуса для создания эффекта кольца
  rotation: 0, // Начальная точка вращения
  circumference: 360, // Угол, который будет покрыт (180 градусов для полукруга)
  plugins: {
    tooltip: {
      enabled: false, // Отключаем всплывающие подсказки
    },
    legend: {
    display: false, // Отключаем легенду
    },
  },
};

return <div style={{position: 'relative'}}>
    <Doughnut data={data} options={options} width={130} height={130} />
    <StyledInfoWrap>
      <StyledInfoHeader>{percentage}%</StyledInfoHeader>
      <StyledInfoText>{add}</StyledInfoText>
    </StyledInfoWrap>
  </div>
};

export default RadialBarChart;

const StyledInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);

`

const StyledInfoHeader = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.01em;

  /* Текст/#333333 текст|кнопки */
  color: #333333;

`
const StyledInfoText = styled.div`
/* +10,39% */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  /* identical to box height, or 107% */
  color: #3BCA1E;

`