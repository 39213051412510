import React, { useState } from 'react';
import styled from 'styled-components'
import moment from 'moment';
import { Input } from 'antd'

interface PropsClientModal {
  card: any;
  setShowFormBonus: any;
  setLoading?: any;
}

moment().format();


const ChangeBonusForm: React.FC<PropsClientModal> = ({ card, setShowFormBonus, setLoading }) => { 
  const [valueAdd, setValueAdd] = useState<number>(0)
  const [valueRemove, setValueRemove] = useState<number>(0)
  
  const handleFormSubmitBonus = () => {
    if (valueAdd > 0) {
      fetch(
        `${process.env.REACT_APP_API_URL}/shops/1/loyalty/cards/${card.id}/bonus-in`,
        {
          method: 'POST',
          body: JSON.stringify({ amount: Number(valueAdd) }),
          headers: {
            'accept': 'application/json',
            'Authorization': `${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json',
          }
        },
      )
        .then(() => {
          setLoading(true);
        })
    }
    if (valueRemove > 0) {
      fetch(
        `${process.env.REACT_APP_API_URL}/shops/1/loyalty/cards/${card.id}/bonus-out`,
        {
          method: 'POST',
          body: JSON.stringify({ amount:valueRemove }),
          headers: {
            'accept': 'application/json',
            'Authorization': `${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json',
          }
        },
      )
    }
    setShowFormBonus(false);
    setValueAdd(0);
    setValueRemove(0);
    setLoading(true);
  };
  
  const handlerAdd = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      setValueAdd(Number(inputValue));
    }
  }
  const handlerRemove = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      setValueRemove(Number(inputValue));
    }
  }
  return (
    <FormWrap>
      <HeaderTitle>Изменить бонусный баланс</HeaderTitle>
      <Br />
      <Title>{`Бонусный баланс карты ${(card.bonus) ? new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(card.bonus)) : '0,00'} вы можете списать или начислить бонусы`}</Title>
      <CountWrap>
        <AddInput
          onChange={handlerAdd}
          value={valueAdd}
          placeholder='Начислить бонусы'
          onKeyDown={(e) => (e.key === 'Enter') ? handleFormSubmitBonus() : null}
        />
        <AddInput
          onChange={handlerRemove}
          value={valueRemove}
          placeholder='Списать бонусы'
          onKeyDown={(e) => (e.key === 'Enter') ? handleFormSubmitBonus() : null}
        />
      </CountWrap>

      <Description placeholder='Комментарии к действию'/>
      <ButtonWrap>
        <CloseButton onClick={()=>setShowFormBonus(false)}>Закрыть</CloseButton>
        <SaveButton onClick={handleFormSubmitBonus}>Сохранить</SaveButton>
      </ButtonWrap>
    </FormWrap>
  )
}

export default ChangeBonusForm;

const HeaderTitle = styled.div`
  /* Изменить бонусный баланс */

  width: 187px;
  height: 40px;
  left: 26px;
  top: 29px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  /* or 111% */

  /* Текст/#333333 текст|кнопки */
  color: #333333;
`
const Br = styled.div`
  width: 298px;
  height: 0px;
  margin: 20px 0px 23px;
  border: 1px solid #EEEFEF;
`
const Title = styled.div`
  width: 299px;
  height: 30px;
  left: 26px;
  top: 112px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  /* or 107% */
  display: flex;
  align-items: center;

  /* Текст/#333333 текст|кнопки */
  color: #333333;

`
const CountWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 18px;
`
const AddInput = styled(Input)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;

  width: 148px;
  height: 35px;

  /* Таблицы/#EEEFEF формы|таблицы */
  background: #EEEFEF;
  border-radius: 5px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`
const Description = styled(Input)`
  /* Frame 159 */
  margin-top: 13px;
  width: 298px;
  height: 92px;
  /* Таблицы/#EEEFEF формы|таблицы */
  background: #EEEFEF;
  border-radius: 5px;
`
const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
`
const CloseButton = styled.div`
/* Закрыть */

  width: 59px;
  height: 28px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  display: flex;
  align-items: center;
  text-align: center;

  /* Текст/#8F8F8F текст */
  color: #8F8F8F;
  cursor: pointer;
`
const SaveButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 58px;
  gap: 10px;

  width: 190px;
  height: 40px;
  cursor: pointer;
  /* Текст/#A7388E текст|кнопки */
  background: #A7388E;
  border-radius: 5px;
`
const FormWrap = styled.div`
  overflow: hidden;
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: flex-start;
  /* min-width: 100%; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1105;
  background-color: #fff;
  /* box-sizing: border-box; */

/* Auto layout */
  padding: 29px 26px 22px;
  border-radius: 5px;
  width: 350px;
  height: 380px;

  background: #FFFFFF;
  border-radius: 5px;

`