import _ from 'lodash';
import { useEffect, useState } from 'react';

export interface InitialState {
  url: string
  loading?: boolean
  data?: any
  error?: any
}

const useGetData = <T = any>(initialState: InitialState) => {
  const [data, setData] = useState<T | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const [params, setParams] = useState(initialState.url);

  async function getData() {
    
    if (!_.isEmpty(initialState.url)) {
      setLoading(true);
      fetch(initialState.url, {
        method: 'GET',
        // credentials: 'include',
        headers: {
          'accept': 'application/json',
          'Authorization': `${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',

        }
      })
        .then(resp => resp.json())
        .then(json => {
          if (json.token && json.token.accessToken) {
            localStorage.setItem('accessToken', json.token.accessToken)
          }
          setData(json);
          setLoading(false);
          setError(undefined);
        })
        .catch(err => {
          setLoading(false);
          setData(undefined);
          setError(err);
        });
    }
  }

  useEffect(() => {
    getData();

    return () => {
      setData(undefined);
      setLoading(false);
      setError(undefined);
    }
  }, [params]);

  return {
    data,
    loading,
    error,
    params,
    setParams,
  };
};

export default useGetData;
