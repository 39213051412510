const getFetchdata = async (url: string, setF: any) => {
    return Promise.all([
      await fetch(url, {
        // credentials: 'include',
        headers: {
          'accept': 'application/json',
          'Authorization': `${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',

        }
      })
        .then(res => res.json())
        .catch((err) => {
          console.log(err);
          
          return []
        })
    ])
      .then(([data]) => {
        if (data) {
          setF(data)
        }
      })
}

export default getFetchdata;