import { styled } from 'styled-components'
import { Table, TableColumnsType } from 'antd';
import { columnsDynamicPage } from './columns';

const columns: TableColumnsType = columnsDynamicPage;

const TableDynamicPage = ({ data, loading, valueRadio }) => {
  return (
    <StyledTable>
      <Table
        pagination={false}
        dataSource={data}
        loading={loading}
        columns={columns}
        size="small"
        summary={(pageData) => {
          let totalAmount1 = 0;
          let totalAmount2 = 0;
          let totalAmount3 = 0;
          let totalAmount4 = 0;
          let totalAmount5 = 0;
          let totalAmount6 = 0;
          if (valueRadio) {
            pageData.forEach(({ cards, cash, credit, tables, revenue, net }: any) => {
              totalAmount1 += (cards)? cards: 0;
              totalAmount2 += (cash)? cash: 0;
              totalAmount3 += (credit)? credit: 0;
              totalAmount4 += tables;
              totalAmount5 += (revenue)? revenue: 0;
              totalAmount6 += (net)? net: 0;
            });
          } else {
            pageData.forEach(({ cards, cash, credit, tables, revenue, net }: any) => {
              totalAmount1 = (cards)? cards: 0;
              totalAmount2 = (cash)? cash: 0;
              totalAmount3 = (credit)? credit: 0;
              totalAmount4 = (tables)? tables: 0;
              totalAmount5 = (revenue)? revenue: 0;
              totalAmount6 = (net)? net: 0;
            });

          }

        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Итого:</Table.Summary.Cell>
              <Table.Summary.Cell index={1}><StyledSum>{(totalAmount1)? new Intl.NumberFormat('ru-RU').format(Number(totalAmount1)): 0} р.</StyledSum></Table.Summary.Cell>
              <Table.Summary.Cell index={2}><StyledSum>{(totalAmount2)? new Intl.NumberFormat('ru-RU').format(Number(totalAmount2)): 0} р.</StyledSum></Table.Summary.Cell>
              <Table.Summary.Cell index={3}><StyledSum>{(totalAmount3)? new Intl.NumberFormat('ru-RU').format(Number(totalAmount3)): 0} р.</StyledSum></Table.Summary.Cell>
              <Table.Summary.Cell index={4}><StyledSum>{(totalAmount4)? new Intl.NumberFormat('ru-RU').format(Number(totalAmount4)): 0} р.</StyledSum></Table.Summary.Cell>
              <Table.Summary.Cell index={5}><StyledSum>{(totalAmount5)? new Intl.NumberFormat('ru-RU').format(Number(totalAmount5)): 0} р.</StyledSum></Table.Summary.Cell>
              <Table.Summary.Cell index={6}><StyledSum>{(totalAmount6)? new Intl.NumberFormat('ru-RU').format(Number(totalAmount6)): 0} р.</StyledSum></Table.Summary.Cell>
            </Table.Summary.Row>
           
          </>
        );
      }}
      />
    </StyledTable>
	)
}

export default TableDynamicPage;


const StyledTable = styled.div`
  .ant-table-thead > tr > th {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;

  }

  .ant-table-footer {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;

  }

  
  table>tbody>tr>td{
    background-color: #ffffff;
    font-family: Inter;
    border: 1px solid #EFEFEF;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    height: 30px;

  }

  .ant-table-summary {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;

  }

  
  
`

const StyledSum = styled.div`
  text-align: right;
  padding-right: 20%;
`
