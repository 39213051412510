import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, Filler } from 'chart.js';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import styled from 'styled-components'
import annotationPlugin from 'chartjs-plugin-annotation';
import BookingSelector from './components/BookingSelector';

Chart.register(Filler, annotationPlugin);

const selectOptions = [
  {key: 'all', value: 'all', label: 'Всего'},
  {key: 'accepted', value: 'accepted', label: 'Подтвержденных'},
  {key: 'declined', value: 'declined', label: 'Отклоненных'},
]

const selectOptionsQ = [
  {key: 'quontity', value: 'quontity', label: 'Количество'},
  {key: 'amount', value: 'amount', label: 'Суммы'},
]

const data = [{
  labels: ['18.01', '24.01', '25.01', '31.01', '01.02', '07.02', '08.02'],
  datasets: [
    {
      label: '',
      data: [3650, 2590, 3400, 3810, 3560, 3550, 3400],
      fill: {
        target: 'origin', // Заливка относительно оси X
        above: 'rgba(148, 129, 205, 0.1)', // Цвет выше линии
        below: 'rgba(148, 129, 205, 0.1)', // Цвет ниже линии
      },
      borderColor: 'rgba(148, 129, 205, 0.25)', // Цвет линии
      tension: 0.4, // Сглаживание линии
    },
  ],
}, {
  labels: ['18.01', '24.01', '25.01', '31.01', '01.02', '07.02', '08.02'],
  datasets: [
    {
      label: '',
      data: [3650, 3590, 2400, 3810, 3560, 3550, 3400],
      fill: {
        target: 'origin', // Заливка относительно оси X
        above: 'rgba(148, 129, 205, 0.1)', // Цвет выше линии
        below: 'rgba(148, 129, 205, 0.1)', // Цвет ниже линии
      },
      borderColor: 'rgba(148, 129, 205, 0.25)', // Цвет линии
      tension: 0.4, // Сглаживание линии
    },
  ],
}, {
  labels: ['18.01', '24.01', '25.01', '31.01', '01.02', '07.02', '08.02'],
  datasets: [
    {
      label: '',
      data: [3650, 3590, 3400, 2810, 3560, 3550, 3400],
      fill: {
        target: 'origin', // Заливка относительно оси X
        above: 'rgba(148, 129, 205, 0.1)', // Цвет выше линии
        below: 'rgba(148, 129, 205, 0.1)', // Цвет ниже линии
      },
      borderColor: 'rgba(148, 129, 205, 0.25)', // Цвет линии
      tension: 0.4, // Сглаживание линии
    },
  ],
}, {
  labels: ['18.01', '24.01', '25.01', '31.01', '01.02', '07.02', '08.02'],
  datasets: [
    {
      label: '',
      data: [1650, 2590, 3400, 2810, 1560, 3550, 3400],
      fill: {
        target: 'origin', // Заливка относительно оси X
        above: 'rgba(148, 129, 205, 0.1)', // Цвет выше линии
        below: 'rgba(148, 129, 205, 0.1)', // Цвет ниже линии
      },
      borderColor: 'rgba(148, 129, 205, 0.25)', // Цвет линии
      tension: 0.4, // Сглаживание линии
    },
  ],
}, {
  labels: ['18.01', '24.01', '25.01', '31.01', '01.02', '07.02', '08.02'],
  datasets: [
    {
      label: '',
      data: [3650, 3590, 1400, 3810, 2560, 1550, 3400],
      fill: {
        target: 'origin', // Заливка относительно оси X
        above: 'rgba(148, 129, 205, 0.1)', // Цвет выше линии
        below: 'rgba(148, 129, 205, 0.1)', // Цвет ниже линии
      },
      borderColor: 'rgba(148, 129, 205, 0.25)', // Цвет линии
      tension: 0.4, // Сглаживание линии
    },
  ],
}, {
  labels: ['18.01', '24.01', '25.01', '31.01', '01.02', '07.02', '08.02'],
  datasets: [
    {
      label: '',
      data: [3650, 1590, 3400, 2810, 3560, 2550, 1400],
      fill: {
        target: 'origin', // Заливка относительно оси X
        above: 'rgba(148, 129, 205, 0.1)', // Цвет выше линии
        below: 'rgba(148, 129, 205, 0.1)', // Цвет ниже линии
      },
      borderColor: 'rgba(148, 129, 205, 0.25)', // Цвет линии
      tension: 0.4, // Сглаживание линии
    },
  ],
}];
  
const options = {
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false, // Убираем линии сетки по оси X
      },
      ticks: {
        color: 'gray', // Цвет текста меток
        backdropColor: 'blue', // Цвет фона меток
        backdropPadding: 2, // Отступы внутри фона меток
      },
    },
    y: {
      grid: {
        display: false, // Убираем линии сетки по оси X
      },
      ticks: {
        display: false, // Убираем подписи по оси Y
      },
    },
  },
  plugins: {
    legend: {
      display: false, // Убираем легенду
    },
    hover: {
      mode: 'nearest',
      intersect: true,
      onHover: (event, chartElement) => {
        const chart = chartElement[0]?.element?.$context?.chart;
        if (chart) {
          const index = chartElement[0].index;
          const xAxis = chart.scales.x;
          xAxis.ticks[index].label = {
            ...xAxis.ticks[index].label,
            backgroundColor: 'blue',
            color: 'white',
            padding: 2,
            borderRadius: 3,
          };
          chart.update();
        }
      },
    },
  },
};

function Plag1() {
  const [select, setSelect] = useState<number>(0);
  const [selectQ, setSelectQ] = useState<number>(0);

  return (
    <Palg1Wrap>
      <StyledHeader>
        <Header>Бронирование</Header>
        <WrapSelect>
          {/* <BookingSelector select={selectQ} setSelect={setSelectQ} selectOptions={selectOptionsQ}/> */}
          <BookingSelector select={select} setSelect={setSelect} selectOptions={selectOptions} />
        </WrapSelect>
      </StyledHeader>
      <Line data={data[select * (1 + selectQ)]} options={options} height={380} width={430}/>
      <style>{`
          .chartjs-render-monitor {
            position: relative;
          }
          .chartjs-render-monitor .tick {
            background-color: blue;
            color: white;
            padding: 2px 4px;
            border-radius: 3px;
          }
        `}
      </style>
    </Palg1Wrap>
  )
}

export default Plag1;

const WrapSelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
`
const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;  
  justify-content: space-between;
`

const Palg1Wrap = styled.div`
  height: 345px; 
  width: 431px;
`

const Header = styled.div`
  margin-bottom: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  /* identical to box height, or 112% */
  display: flex;
  align-items: center;

  /* Текст/#333333 текст|кнопки */
  color: #333333;


`
