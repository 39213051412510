import { useState, useEffect, useRef } from "react";
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

interface PropsSidebar {
  sidemenu: any;
  onSetActiveSubmenu: any;
  onSetNumActiveSubmenu?: any;
  height?: number;
}

const Sidebar = ({ sidemenu, onSetActiveSubmenu, height }: PropsSidebar) => {
  const { pathname } = window.location;
  const [click, setClick] = useState(false);
  const [activeMenuItemPath, setActiveMenuItemPath] = useState(pathname)

  const activeItemRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setActiveMenuItemPath(pathname)
  }, [pathname])

  // const changeActiveMenuItem = (link: string) => {
  //   if (link === 'disabled') return
  //   setActiveMenuItemPath(link)
    
  // }

  const handleClick = () => setClick(!click);
  
  const useAuth = () => {
    const user = localStorage.getItem("user")
    if (user) {
      return true
		} else {
			return false
		}
	}
	const user = useAuth()
	
	return (
    <>
      {user && (
      <Nav key={'sidebar'}>
        <Menu onClick={handleClick}>
          {sidemenu?.map((item, ind) => (
            <StyledStage key={ind}>
              <MenuLink to={item.to} key={`link-${item.text}`}>
                <StyledLinkWrap
                  ref={activeMenuItemPath.includes(item.to) ? activeItemRef : null}
                  $activeitem={activeMenuItemPath.includes(item.to) ? "true" : ''}
                  // onClick={() => {
                  //   onSetActiveSubmenu(false)
                  //   setActiveMenuItemPath(item.to)
                  // }}
                  key={item.text}
                  $pathname={pathname}
                >
                  <div>
                    {item.icon}
                  </div>
                  <StyledTextWrap>
                    
                      {item.name}
                    
                  </StyledTextWrap>
                </StyledLinkWrap>
              </MenuLink>
              <StyledSubs>
                {item && item.subs && item.subs.map(obj => (
                  <SubLink to={obj.to}>{obj.name}</SubLink>
                ))}

              </StyledSubs>
            </StyledStage>
          ))}
        </Menu>
        
      </Nav>

      )}
    </>
	)
}

export default Sidebar;

const Nav = styled.nav<{
  height?: number;
}>` 
  font-size: 18px;
  display: flex;
  top: 0;
  left: 0;
  z-index: 1;
  max-width: 1366px;
  height: ${({ height }) => height ? `${height}px`: '60px'};
  background-color: #2E1C65;
`;

export const Menu = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px 20px;
  padding: 15px 0px 0px 0px;
  width: 1366px;
`;

const StyledStage = styled.div`
  display: flex;
  flex-direction: column;
  
`

const StyledSubs = styled.div`
  display: flex;
  flex-direction: column;
  /* Карты и макеты */

  width: 300px;
  height: 26px;
  padding-left: 46px;
  /* Текст */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  /* identical to box height, or 200% */
  display: flex;
  cursor: pointer;
  /* Текст/#FFFFFF 50% текст|иконки */
  color: rgba(255, 255, 255, 0.5);


`

export const SubLink = styled(Link)`
  text-decoration: none;
  /* font-weight: bold; */
  /* font-size: 1rem; */
  vertical-align: middle;
  color: rgba(255, 255, 255, 0.5);;
  /* display: block; */
  padding: 0;
  margin: 0;
  transition: all .2s ease;

  &:hover {
    color: #CD6EE5;
    /* border-bottom: 2px solid #CD6EE5 */
    /* transform: traslateY(-3rem); */
  }
`;

export const MenuLink = styled(Link)`
  text-decoration: none;
  font-weight: bold;
  font-size: 2rem;
  vertical-align: middle;
  color: #fff;
  /* display: block; */
  padding: 0;
  margin: 0;
  transition: all .2s ease;
`;

export const StyledLinkWrap = styled.div<{
  $activeitem: string | null;
  $pathname: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  line-height: 23px;
  height: 46px;
  min-width: 200px;
  
  /* display: block; */
  background-color: #2E1C65;
  cursor: pointer;
  
  & span {
    /* width: ${({ $pathname }) => $pathname.includes('admin') ? '100%' : '90%'}; */
    font-size: 12px;
    font-weight: 400;
    line-height: 23px;
    color: #ffffff80;
  
    
    /* text-align: center; */
  }

  & svg path{
    /* display: inline-block; */
    height: 25px;
    width: 25px;
  }
  /* &:active {
    color: #E38B06;
  } */

  &:hover {
    color: #CD6EE5;
    /* border-bottom: 2px solid #CD6EE5 */
    /* transform: traslateY(-3rem); */
  }

  ${({ $activeitem }) => {
    return $activeitem && css`
      color:  #CD6EE5;

    `
  }}
`

const StyledTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

`