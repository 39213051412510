import moment from 'moment';
import 'moment/locale/ru';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/ru';

moment.updateLocale('ru', {
  week: {
    dow: 1,
  },
});

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale('ru');

export default moment;
