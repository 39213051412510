import React from "react"

import {Navigate, Outlet} from "react-router-dom"
import { IUser } from "../types"

const useAuth = () => {
	//get item from localstorage

  	let auth: boolean = false
	let user: IUser
	let role: any

	const _user = localStorage.getItem("user")
	const _role = localStorage.getItem("role")

	if (_user && _role) {
		user = JSON.parse(_user)
    role = JSON.parse(_role)
    auth = true
		if (user) {
			return {
				auth: auth,
				user: user,
				role: role,
			}
		}
	} else {
		return {
			auth: auth,
			user: null,
			role: null,
		}
	}
}

//protected Route state
type ProtectedRouteType = {
	roleRequired?: "ADMIN" | "USER"
}

const ProtectedRoutes = (props: ProtectedRouteType) => {
  const auth  = useAuth()
	
	//if the role required is there or not
	if (props.roleRequired) {
		return auth ? (
			props.roleRequired === auth.role ? (
				<Outlet />
			) : (
				<Navigate to="/denied" />
			)
		) : (
			<Navigate to="/login" />
		)
	} else {
		return auth ? <Outlet /> : <Navigate to="/login" />
	}
}

export default ProtectedRoutes