import React from 'react';

interface SidebarsType {
  isActiveSubmenu: boolean,
  setActiveSubmenu: (v: any) => void,
  numActiveSubmenu: any,
  setNumActiveSubmenu: (v: any) => void,
}

const SidebarsContext = React.createContext<SidebarsType>({
  isActiveSubmenu: false,
  setActiveSubmenu: () => { },
  numActiveSubmenu: undefined,
  setNumActiveSubmenu: () => {},
});

export const useSidebarsContext = () => React.useContext(SidebarsContext);

export default SidebarsContext;