import { ReactNode } from "react";
import { Outlet } from "react-router-dom"
import Navbar from "./navbar/Navbar"
import styled from 'styled-components'
import useAuth from "../hooks/useAuth";

interface PropsLayout {
  children: ReactNode;
  sidemenu: any;
}
const Layout = ({children, sidemenu}: PropsLayout) => {
  const auth = useAuth();
  
  return (
    <StyledContainer className="App">
      <Navbar sidemenu={sidemenu}/>
      <StyledContent>
          {/* <StyledSidebar>
            <Sidebar sidemenu={sidemenu} />
          </StyledSidebar> */}
          <StyledChildren>
            {children}
            <Outlet />
          </StyledChildren>
        </StyledContent>
    </StyledContainer>
  )
} 

const StyledContainer = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  background: #F7F7F7;
  /* box-sizing: border-box; */
  /* overflow-x: hidden;  */
`

const StyledContent = styled.div`
  display: flex;
  max-width: 1366px;
  margin: 0 auto;  
  background: #F7F7F7;
  

`

const StyledChildren = styled.div`
  display: flex;
  min-width: 1366px;
  background: #F7F7F7;
  
`

export default Layout