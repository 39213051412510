import styled from 'styled-components'
import moment from 'moment';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { Icons } from '../../assets';

moment().format();

const tableData = [
  {createdAt: '02.07.2024 14:43:27', report: 'Журнал лидов', period: '24.06.2024 - 30.06.2024', login: 'gkfenix.admin1n@gmail.com', status: 'Готов'},
  {createdAt: '02.07.2024 14:43:27', report: 'Журнал лидов', period: '24.06.2024 - 30.06.2024', login: 'gkfenix.admin1n@gmail.com', status: 'Готов'},
  {createdAt: '02.07.2024 14:43:27', report: 'Журнал лидов', period: '24.06.2024 - 30.06.2024', login: 'gkfenix.admin1n@gmail.com', status: 'Готов'},
  {createdAt: '02.07.2024 14:43:27', report: 'Журнал лидов', period: '24.06.2024 - 30.06.2024', login: 'gkfenix.admin1n@gmail.com', status: 'Готов'},
  {createdAt: '02.07.2024 14:43:27', report: 'Журнал лидов', period: '24.06.2024 - 30.06.2024', login: 'gkfenix.admin1n@gmail.com', status: 'Готов'},
  {createdAt: '02.07.2024 14:43:27', report: 'Журнал лидов', period: '24.06.2024 - 30.06.2024', login: 'gkfenix.admin1n@gmail.com', status: 'Готов'},
  {createdAt: '02.07.2024 14:43:27', report: 'Журнал лидов', period: '24.06.2024 - 30.06.2024', login: 'gkfenix.admin1n@gmail.com', status: 'Готов'},
  {createdAt: '02.07.2024 14:43:27', report: 'Журнал лидов', period: '24.06.2024 - 30.06.2024', login: 'gkfenix.admin1n@gmail.com', status: 'Готов'},
]

const columns: TableColumnsType = [
  {
    title: 'Создан',
    dataIndex: 'createdAt',
    width: 150,
    render: ((record) => {
      return <span>{record ? moment.utc(record).format('DD.MM.YYYY HH.mm.ss') : '-'}</span>
    }),
  },
  {
    title: 'Отчет',
    dataIndex: 'report',
    width: 120,
  },
  {
    title: 'Период',
    dataIndex: 'period',
    width: 150,
  },
  {
    title: 'Логин',
    dataIndex: 'login',
    width: 120,
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    width: 60,
  },
  {
    title: 'Скачать',
    dataIndex: 'status',
    width: 30,
    align: 'center',
    render: (value) => (value)
        ? <Icons.DownloadFill />
      : <Icons.CircleQuestion />,
  },
  {
    title: 'На почту',
    dataIndex: 'status',
    width: 30,
    align: 'center',
    render: (value) => (value)
        ? <Icons.MailFill />
        : <Icons.CircleQuestion />
  },

];
  

const ReportsPage = () => {
  return (
    <Wrapper>
      <Title>Заказные отчеты</Title>
      <TableWrapper>
        <Table
          size='small'
          columns={columns}
          rowKey={(rec) => rec.id}
          dataSource={tableData}
          pagination={{ pageSize: 20 }}
        />

      </TableWrapper>
      
    </Wrapper>
   
  )
}

export default ReportsPage;

const TableWrapper = styled.div`
  margin-top: 10px;
  .ant-table-thead {
    height: 50px; /* Вы можете установить желаемую высоту */
    line-height: 50px; /* Установите такое же значение, как и высота, для вертикального центрирования содержимого */
    /* Rectangle 91 */
    margin-bottom: 5px !important;
    background: rgba(148, 129, 205, 0.25);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px 5px 0px 0px;
  }
  .ant-table-thead>tr>th::before {
    content: none !important;
  }
  .ant-table-cell {
    border-right: none !important; 
    border-left: none !important;/* Убираем вертикальные границы между ячейками */
  }

  .ant-table-row .ant-table-cell {
    height: 46px !important; 
    /* 79991233232 */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    align-items: center;

    /* Текст/#333333 текст|кнопки */
    color: #333333;

    & svg {
      margin-top: 9px;
      cursor: pointer;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* min-width: 100%; */
  width: 100%;
  min-height: calc(100vh - 110px);
  top: 0px;
  background-color: #f7f7f7;
  
  .ant-table-thead > tr > th {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;

  }

  .ant-pagination {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;
    margin: 0 !important;
    margin-top: 5px !important;
    padding: 16px;
    /* Frame 117 */

    background: #FFFFFF;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 5px 5px;

  }

  
  table>tbody>tr>td {
    background-color: #ffffff;
    font-family: Inter;
    border: 1px solid #EFEFEF;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    height: 30px;
  }
`

const Title = styled.div`
  /* Заказные отчеты */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  /* identical to box height, or 112% */
  display: flex;
  align-items: center;
  margin: 50px 0px 15px;

  /* Текст/#333333 текст|кнопки */
  color: #333333;


`
