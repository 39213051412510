import { ReactComponent as LogoWhite } from './LogoWhite.svg';
import LogoBlack from './Logo-proga.svg';
import { ReactComponent as CustomEmptyImage } from './CustomEmptyImage.svg';
import { ReactComponent as NoDBImage } from './NoDBImage.svg';
import Frame1 from './Frame1.png';
import Frame2 from './Frame2.png';
import Frame3 from './Frame3.png';
import Frame0 from './Frame0.png';
import Frame5 from './Frame5.png';
import GirlPicture from './girlPicture.png';
import { ReactComponent as MiskLogo } from './MiskLogo.svg';

export const Logos = {
    LogoWhite,
    LogoBlack,
    CustomEmptyImage,
    NoDBImage,
    Frame0,
    Frame1,
    Frame2,
    Frame3,
    Frame5,
    GirlPicture,
    MiskLogo,
};
