import _ from 'lodash'

export const calcTableData = (formedData) => {
  const result = _(formedData)
    .groupBy('target')
    .map((objs: any, key: any) => { 
      const totalAmount = _.size(objs)
      const total0 = _.size(_.filter(objs, { state: 0 }))
      const total1 = _.size(_.filter(objs, { state: 1 }))
      const total2 = _.size(_.filter(objs, { state: 2 }))
      const total3 = _.size(_.filter(objs, { state: 3 }))
      const total4 = _.size(_.filter(objs, { state: 4 }))
      const totalRequest = total0
      const dRequest = (totalAmount) ? Math.round(totalRequest / totalAmount * 1000) / 10 : 0
      const totalAccept = total2 + total3
      const dAccept = (totalAmount) ? Math.round(totalAccept / totalAmount * 1000) / 10 : 0
      const totalDecline = total1 + total4
      const dDecline = (totalAmount) ? Math.round(totalDecline / totalAmount * 1000) / 10 : 0
      return {
        key: key,
        totalAmount: totalAmount,
        totalRequest: totalRequest,
        totalAccept: totalAccept,
        totalDecline: totalDecline,
        dRequest: dRequest,
        dAccept: dAccept,
        dDecline: dDecline,
      }
    })
    .value()
  return result
}

export const calcTableDataWithNestedByGroupTarget = (callsData) => {
  const formedCallsData = callsData.map(i => {
    if (i.group === null) {
      i.group = "Прочие"
    }
    return i
    
  })
  return _(formedCallsData)
    // .remove(i => i.SHIFTDATE === '2024-06-20')
    .groupBy('group')
    .map((objs: any, key: any) => {
      const totalMissed = _.sumBy(objs, 'missed')
      const totalSuccess = _.sumBy(objs, 'success')
      const totalAutoanswer = _.sumBy(objs, 'autoanswer')
      const totalAmount = totalMissed + totalSuccess + totalAutoanswer
      const dMissed = (totalAmount) ? Math.round(totalMissed / totalAmount * 1000) / 10 : 0
      const dSuccess = (totalAmount) ? Math.round(totalSuccess / totalAmount * 1000) / 10 : 0
      const dAutoanswer = (totalAmount) ? Math.round(totalAutoanswer / totalAmount * 1000) / 10 : 0
      return {
        key: key,
        'totalSuccess': totalSuccess,
        'dSuccess': dSuccess,
        'totalMissed': totalMissed,
        'dMissed': dMissed,
        'totalAutoanswer': totalAutoanswer,
        'dAutoanswer': dAutoanswer,
        'totalAmount': totalAmount,
        'children': _(objs).filter((o) => o.group === key)
          .groupBy('target')
          .map((item, ind) => {
            const ntotalMissed = _.sumBy(item, 'missed')
            const ntotalSuccess = _.sumBy(item, 'success')
            const ntotalAutoanswer = _.sumBy(item, 'autoanswer')
            const ntotalAmount = ntotalMissed + ntotalSuccess + ntotalAutoanswer
            const ndMissed = (ntotalAmount) ? Math.round(ntotalMissed / ntotalAmount * 1000) / 10 : 0
            const ndSuccess = (ntotalAmount) ? Math.round(ntotalSuccess / ntotalAmount * 1000) / 10 : 0
            const ndAutoanswer = (ntotalAmount) ? Math.round(ntotalAutoanswer / ntotalAmount * 1000) / 10 : 0
            return {
              key: `${ind}`,
              target: ind,
              'totalSuccess': ntotalSuccess,
              'dSuccess': ndSuccess,
              'totalMissed': ntotalMissed,
              'dMissed': ndMissed,
              'totalAutoanswer': ntotalAutoanswer,
              'dAutoanswer': ndAutoanswer,
              'totalAmount': ntotalAmount,
            }
          })
          .value()
      }
    })
    .value()
}

export const test = (callsData) => {
    
  return _(callsData)
      // .remove(i => i.SHIFTDATE === '2024-06-20')
      .groupBy('CLOSESTATION')
      .map((objs: any, key: any) => ({
        key: key,
        'closeStation': key,
        'totalAmount': _.sumBy(objs, 'ORIGINALSUM'),
        'nested': _(objs).filter((o) => o.CLOSESTATION === key)
          .groupBy('SHIFTDATE')
          .map((item, ind) => ({
              key: `${key}-${ind}`,
              shiftDate: ind,
              shiftDateAmount: _.sumBy(item, 'ORIGINALSUM'),
              subNested: _(item).filter(k => k.SHIFTDATE === ind)
                .groupBy('CURRENCYTYPE')
                .map((subitem, kInd) => ({
                  key: `${key}-${ind}-${kInd}`,
                  currencyType: kInd,
                  currencyTypeAmount: _.sumBy(subitem, 'ORIGINALSUM'),
                  details: _(subitem).filter(r => r.CURRENCYTYPE === kInd)
                    .map((detailItem, detailInd) => ({
                      key: `${key}-${ind}-${kInd}-${detailInd}`,
                      currency: detailItem.CURRENCY,
                      originalSum: detailItem.ORIGINALSUM,
                    }))
                    .value()
                  
                }))
                  .value()
          })
        )
        .value()
      }))
      .value()
}