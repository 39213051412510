import { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, Filler } from 'chart.js';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import styled from 'styled-components'
import annotationPlugin from 'chartjs-plugin-annotation';
import useFetchData from '../../../hooks/useFetchData';
import { LineChart } from './LineChart';
import { calcSortedDataChart } from '../../rkeeper/components/consts';
import BarChart from './BarChart';

Chart.register(Filler, annotationPlugin);

const urlForRemains = `${process.env.REACT_APP_API_URL}/shops/1/reports/revenue/station/days?from=2024-08-01&to=2024-09-15`
 
function Plag3() {
  const [chartData, setChartData] = useState<any>(undefined);
  const {
    data: viruchkaData,
    loading,
    setParams: setHeaderParams,
  } = useFetchData(urlForRemains,
    [urlForRemains]);
  
    useEffect(() => {
      if (viruchkaData && Array.isArray(viruchkaData)) { 
        setChartData(calcSortedDataChart(viruchkaData))
      } else {
        setChartData(undefined)
      }
      
    }, [viruchkaData])

  return (
    <Palg3Wrap>
      <Header>Карты и лояльность</Header>
      <BarChart />
      <style>{`
          .chartjs-render-monitor {
            position: relative;
          }
          .chartjs-render-monitor .tick {
            background-color: blue;
            color: white;
            padding: 2px 4px;
            border-radius: 3px;
          }
        `}
      </style>
    </Palg3Wrap>
  )
}

export default Plag3;

const Palg3Wrap = styled.div`
  height: 380px; 
  width: 860px;
`

const Header = styled.div`
  margin-bottom: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  /* identical to box height, or 112% */
  display: flex;
  align-items: center;

  /* Текст/#333333 текст|кнопки */
  color: #333333;


`
