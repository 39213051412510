import { CSSProperties, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components'
import moment from 'moment';
import dayjs from 'dayjs';
import { PuffLoader } from 'react-spinners';
import useFetchData from '../../hooks/useFetchData';
import { DatePicker, Select } from 'antd'
import { useApplication } from '../../context/ApplicationContext';
import { calcTableData } from './components/constsTableReportPage';

import { Icons } from '../../assets';

import { LineChart } from '../rkeeper/components/LineChart';
import BookingReportTable from './components/BookingReportTable';

const { RangePicker } = DatePicker;

const selectOptions = [
  { key: 'request', value: 'request', label: 'Ожидание' },
  { key: 'ok', value: 'ok', label: 'Отклонить' },
  // { key: 'canceled', value: 'cancel', label: 'Отклонить' },
  { key: 'approve', value: 'approve', label: 'Подтвердить' },
  { key: 'complete', value: 'complete', label: 'Завершить' },
  { key: 'cancel', value: 'cancel', label: 'Отказать' },
]

moment().format();

interface ICall {
  target: string
  date: string
  success: number
  missed: number
  autoanswer: number
}

const BronirovaniePage = () => {
  const { dateRange, setDateRange } = useApplication()
  const [selectValue, setSelectValue] = useState(null);
  const [data, setData] = useState<any>(undefined);
  const [dataChart, setDataChart] = useState<any | null>(null);
  const [dataTable, setDataTable] = useState<any | null>(null);
  const [filteredData, setFilteredData] = useState<any>(undefined);
  const [formatedTargetsArray, setFormatedTargetsArray] = useState<string[]>([])
  const [formatedDatesArray, setFormatedDatesArray] = useState<string[]>([])
  
  useEffect(() => {
    if (filteredData) {
      const dataFilteredForChart = {
        // key: selectValue,
        range: formatedDatesArray,
        values: [
          // { key: 'Ожидание', range: formatedDatesArray, values: filteredData[0]},
          {
            key: 'Подтвержденных',
            range: formatedDatesArray,
            values: (filteredData[2])
              ? filteredData[2].map((num, index) => num + (filteredData[3] && filteredData[3][index]) ? filteredData[3][index] : 0)
              : (filteredData[3]) ? filteredData[3] : formatedDatesArray.map(i => 0)
          },
          {
            key: 'Отказ',
            range: formatedDatesArray,
            values: ((filteredData[1]))
              ? filteredData[1].map((num, index) => num + (filteredData[4] && filteredData[4][index]) ? filteredData[4][index] : 0)
              : (filteredData[4]) ? filteredData[3] : formatedDatesArray.map(i => 0)
          },
        ]
      }
      setDataChart(dataFilteredForChart)

    }
  }, [filteredData, formatedDatesArray])

  useEffect(() => {
    const filtered = (selectValue !== null && data)
      ? data.filter(o => o.target === selectValue)
      : data
    const result = {};
    if (filtered) {
      filtered.forEach(item => {
        const date = dayjs(item.createdAt).format('YYYY-MM-DD');
        if (!result[item.state]) {
          result[item.state] = new Array(formatedDatesArray.length).fill(0);
        }
        const index = formatedDatesArray.indexOf(date);
        result[item.state][index] += 1;
      });
      setFilteredData(result)

    }
  }, [data, selectValue, formatedDatesArray])

  const from = useMemo(() => {
    return dateRange && dateRange.from ? dateRange.from : moment().add(-1, 'month').utc().valueOf()
  } , [dateRange])
  const to = useMemo(() => dateRange && dateRange.to ? dateRange.to : moment().utc().valueOf(), [dateRange])

  // const urlForRemains = useMemo(() => {
  //   return `${process.env.REACT_APP_API_URL}/shops/1/reports/booking`
  // }, [from, to]) 

  // const {
  //   data: bookingData,
  // } = useFetchData(urlForRemains,
  //   [urlForRemains, from, to]);
  
  const urlForBookingStatistic= useMemo(() => {
    return `${process.env.REACT_APP_API_URL}/shops/1/booking`
  }, [from, to])

  const {
    data: atsData,
    loading,
  } = useFetchData(urlForBookingStatistic,
    [urlForBookingStatistic, from, to]);
  
  useEffect(() => {
    
    if (atsData && atsData.rows) {
      const { rows } = atsData
      const plData = rows.map(i => {
        if (i.source === null) {
          if (i.promouter) {
            i.target = (i.promouter.firstName) ? i.promouter.firstName : ''
            i.target = (i.promouter.lastName) ? i.target + ' ' + i.promouter.lastName : i.target
            // i.target = (i.promouter.patronymic) ? i.target + ' ' + i.promouter.patronymic : i.target
          } else {
            i.target = "Прочие"
          }
        } else {
          i.target = i.source
        }
        return i
        
      })
      setData(plData)
      const datesArr: string[] = plData.map((item: any) => dayjs(item.createdAt).format('YYYY-MM-DD'))
        .sort((a, b) => {
          const dateA = new Date(a).getTime();
          const dateB = new Date(b).getTime();
          return dateA - dateB;
        });
      
      const uniqueSortedDates: string[] = Array.from(new Set(datesArr));
      const newData = calcTableData(plData)
      const unuqueTargets = newData.map(o => o.key)
      setDataTable(newData)
      setFormatedDatesArray(uniqueSortedDates)
      setFormatedTargetsArray(unuqueTargets)
    }
  }, [atsData])

  const cssOverride: CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
  }

  const onDateChange = (date: any, dateString: [string, string]) => {
    
    if (date) {
      setDateRange({
        from: dayjs(dateString[0]).add(1, 'day').valueOf(),
        to: dayjs(dateString[1]).add(1, 'day').valueOf(),
      })
      
      
    } else {
      // setHistoryDateRange([])
    }
  }

  const handleChange = (value: any) => {
    setSelectValue(value)
  };

  return (
    <Wrapper>
      <Header>
        <StyledHeadTitle>Отчет по бронированию</StyledHeadTitle>
        <StyledCaledarWrapper>
          <StyledCalendarMemo>
            <Icons.IconSlideTrendFill />
            Сравнение периодов
          </StyledCalendarMemo>
          <StyledRangePicker
            suffixIcon={<Icons.IconMainCalendar />}
            value={[dayjs(from), dayjs(to)]}
            format={['YYYY-MM-DD', 'YYYY-MM-DD']}
            onChange={onDateChange}
          />
        </StyledCaledarWrapper>
      </Header>
      {loading ? (
        <PuffLoader color="#E65E20" cssOverride={cssOverride} />
      ) : (
        <div>
          {dataChart && (<ChartsWrapper>
            <StyledSelect 
              value={selectValue}
              onChange={handleChange} 
                options={[
                  {value: null, label: 'Все'},
                  ...formatedTargetsArray.map(i => ({
                value: i,
                label: i,
              }))]}
            />
        
            <LineChart dataSource={dataChart} />
          </ChartsWrapper>)}
          {dataTable && <BookingReportTable
            dataValues={dataTable}
            loading={loading}
          />}
          
        </div>
      )}
    </Wrapper>)
}

export default BronirovaniePage;

const StyledSelect = styled(Select)`
  /* Frame 148 */
  position: absolute;
  box-sizing: border-box;
  margin-top: -10px;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 5px 10px; */
  gap: 10px;

  width: 175px;
  height: 38px;
`

const Wrapper = styled.div`
  min-width: 100%;
  height: 80%h;
  background-color: #F7F7F7;
  padding: 16px 29px 16px 0px;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

const StyledRangePicker = styled(RangePicker)`
  width: 250px;
  color: #ffffff;
  
`

const StyledHeadTitle = styled.label`
  font-family: 'Inter';
  font-size: 25px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #333333;
`

const StyledCaledarWrapper = styled.div`
  display: flex;
  gap: 10px;
  color: #ffffff;
  
  & svg {
    width: 20px;
    height: 20px;
  }
  .ant-picker {
    background-color: #A7388E;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
  }

  .ant-picker .ant-picker-input,
  .ant-picker .ant-picker-range,
  .ant-picker .ant-picker-input-active .ant-picker-input-placeholder {
    display: flex;
    flex-direction: row-reverse;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #ffffff;

  }

  .ant-picker .ant-picker-input .ant-picker-input-active {
    color: #ffffff !important;
  }

  .ant-picker .ant-picker-input .ant-picker-input-placeholder {
    color: #ffffff !important;
  }

  .ant-picker .ant-picker-input span.ant-picker-suffix,
  .ant-picker.ant-picker-range span.ant-picker-suffix {
    order: -1;
    margin-left: 0;
    margin-right: 10px; /* Для отступа иконки от текста */
    color: #ffffff;
  }

  .ant-picker.ant-picker-range.ant-picker-range-separator {
    color: #ffffff !important;
    /* display: none; */
  }

  .ant-picker-range-separator > span {
    color: #ffffff !important; /* Замените #ваш_цвет на нужный код цвета */
  }
  
  .ant-picker-clear {
    color: #ffffff !important; /* Замените #ваш_цвет на нужный код цвета */
  }
  
  .ant-picker .ant-picker-clear {
    position: absolute;
    right: 217px;
    /* color: #ffffff !important; */
  }


`

const StyledCalendarMemo = styled.div`
  display: flex;
  width: 220px;
  height: 40px;
  gap: 10px;
  background-color: #E9E9E9;
  border-radius: 5px 0px 0px 0px;
  opacity: 0px;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #333333;

`

const ChartsWrapper = styled.div`
  height: 470px;
  width: 100%;
  padding: 30px 30px 70px;
  background-color: #fff;
  border-radius: 5px;
`
