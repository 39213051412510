import { styled } from 'styled-components'
import { Table, TableColumnsType } from 'antd';
import { columnsLTVTable } from './columns';

interface TableData {
  key: React.Key;
  SUM: number;
  CURRENCYTYPE?: string;
  CURRENCY?: string;
  ORIGINALSUN?: number;
  SHIFTDATE?: string;
  nested?: TableData[];
}

const columns: TableColumnsType<TableData> = columnsLTVTable;

const LTVTable = ({ dataValues, loading }) => {
  
  return (
    <StyledTable>
      <Table
        pagination={false}
        dataSource={dataValues}
        loading={loading}
        columns={columns}
        size="small"
        summary={(pageData) => {
          let totalAmount1 = 0;
          let totalAmount2 = 0;
          let totalAmount3 = 0;
          let totalAmount4 = 0;
          let totalAmount5 = 0;
          let totalAmount6 = 0;
          pageData.forEach(({ man, ltv_man, woman, ltv_woman, all, ltv_all }: any) => {
            totalAmount1 += man;
            totalAmount2 = ltv_man;
            totalAmount3 += woman;
            totalAmount4 = ltv_woman;
            totalAmount5 += all;
            totalAmount6 = ltv_all;
          });

        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Всего</Table.Summary.Cell>
              <Table.Summary.Cell index={1}><StyledSum>{new Intl.NumberFormat('ru-RU').format(Number(totalAmount1))}</StyledSum></Table.Summary.Cell>
              <Table.Summary.Cell index={2}><StyledSum>{new Intl.NumberFormat('ru-RU').format(Number(totalAmount2))}%</StyledSum></Table.Summary.Cell>
              <Table.Summary.Cell index={3}><StyledSum>{new Intl.NumberFormat('ru-RU').format(Number(totalAmount3))}</StyledSum></Table.Summary.Cell>
              <Table.Summary.Cell index={4}><StyledSum>{new Intl.NumberFormat('ru-RU').format(Number(totalAmount4))}%</StyledSum></Table.Summary.Cell>
              <Table.Summary.Cell index={5}><StyledSum>{new Intl.NumberFormat('ru-RU').format(Number(totalAmount5))}</StyledSum></Table.Summary.Cell>
              <Table.Summary.Cell index={6}><StyledSum>{new Intl.NumberFormat('ru-RU').format(Number(totalAmount6))}%</StyledSum></Table.Summary.Cell>
            </Table.Summary.Row>
           
          </>
        );
      }}
      />
    </StyledTable>
	)
}

export default LTVTable;


const StyledTable = styled.div`
  .ant-table-thead > tr > th {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;

  }

  .ant-table-footer {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;

  }

  
  table>tbody>tr>td{
    background-color: #ffffff;
    font-family: Inter;
    border: 1px solid #EFEFEF;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    height: 30px;

  }

  .ant-table-summary {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;

  }

  
  
`

const StyledSum = styled.div`
  text-align: right;
  padding-right: 20%;
`
