import React from 'react';
import styled from 'styled-components'
import { IDateRangeType } from '../../../types';
import { useApplication } from '../../../context/ApplicationContext';
import moment from 'moment';

moment().format()

interface PropsNavbar {
  activeIndex: number
  setActiveIndex: any
}

const DATE_FORMAT = 'YYYY-MM-DD'

const NavbarTabsModal: React.FC<PropsNavbar> = ({activeIndex, setActiveIndex}) => { 
  const { dateRange, setDateRange } = useApplication()

  const handleButtonClick = (index) => {
    setActiveIndex(index);
    if (index === 0) {
      const updatedDate = {
        from: moment().utc().format(DATE_FORMAT).valueOf(),
        to: moment().utc().format(DATE_FORMAT).valueOf(),
      }
      setDateRange(updatedDate)
    }
    if (index === 1) {
      const updatedDate = {
        from: moment().utc().add(-1, 'day').format(DATE_FORMAT).valueOf(),
        to: moment().utc().add(-1, 'day').format(DATE_FORMAT).valueOf(),
      }
      setDateRange(updatedDate)
    }
    if (index === 2) {
      const updatedDate = {
        from: moment().utc().startOf('week').format(DATE_FORMAT).valueOf(),
        to: moment().utc().format(DATE_FORMAT).valueOf(),
      }
      setDateRange(updatedDate)
    }
    if (index === 3) {
      const updatedDate = {
        from: moment().utc().startOf('month').format(DATE_FORMAT).valueOf(),
        to: moment().utc().format(DATE_FORMAT).valueOf(),
      }
      setDateRange(updatedDate)
    }
    if (index === 4) {
      const updatedDate = {
        from: moment().utc().startOf('quarter').format(DATE_FORMAT).valueOf(),
        to: moment().utc().format(DATE_FORMAT).valueOf(),
      }
      setDateRange(updatedDate)
    }
  };

  return (
    <NavbarTabsModalWrap>
      <RadioButton $isActive={activeIndex === 0} onClick={() => handleButtonClick(0)}><span>Сегодня</span></RadioButton>
      <RadioButton $isActive={activeIndex === 1} onClick={() => handleButtonClick(1)}><span>Вчера</span></RadioButton>
      <RadioButton $isActive={activeIndex === 2} onClick={() => handleButtonClick(2)}><span>Неделя</span></RadioButton>
      <RadioButton $isActive={activeIndex === 3} onClick={() => handleButtonClick(3)}><span>Месяц</span></RadioButton>
      <RadioButton $isActive={activeIndex === 4} onClick={() => handleButtonClick(4)}><span>Квартал</span></RadioButton>
      <Highlight style={{ transform: `translateX(${activeIndex * 85 - 8}px)` }} />
    </NavbarTabsModalWrap>
  )
}

export default NavbarTabsModal;

const Highlight = styled.div`
  position: absolute;
  background-color: #fa0;
  transform: translateX(0);
  transition: transform 0.3s ease;
  z-index: 1;
  width: 73px;
  height: 28px;

  background: #FFFFFF;
  border-radius: 5px;
`

const NavbarTabsModalWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 404px;
`

const RadioButton = styled.div<{
  $isActive: boolean;
}>`
  /* Сегодня */

  width: 59px;
  height: 28px;
  margin-right: 35px;
  /* Пункты меню (хедер) */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  color: #333333;

  /* Frame 64 */
  display: flex;
  /* Auto layout */
  z-index: 10;
  align-items: center;
  justify-content: center;
  border-radius: 25px;

  transition: color 0.5s ease;

  cursor: pointer;
  & span {
    text-align: center;
    vertical-align: middle;
    
  }
`
