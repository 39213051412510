import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Icons, Logos } from '../../../assets';
import getFetchData from "../../../utils/getFetchData";

interface IProps {
  promoInfo: any;
}

interface IFeatures {
  id: string
  name: string
  shop_id: string
  value: string
}
const HeaderInfo: React.FC<IProps> = ({ promoInfo }) => {
  const [features, setFeatures] = useState<IFeatures[]>([])
  const [contacts, setContacts] = useState<any[]>([])
  // const { features } = promoInfo
  
  const urlFeatures = `${process.env.REACT_APP_API_URL}/shops/1/promo/features`
  const urlContasts = `${process.env.REACT_APP_API_URL}/shops/1/promo/contacts`
  useEffect(() => {
    getFetchData(urlFeatures, setFeatures)
    getFetchData(urlContasts, setContacts)
  }, [])

  const SocialIcon = (({ item }) => {
    switch (item.type) {
      case 'twitter':
        return (<a key={item.id} href={item.href}><Icons.Social.SocialIconSite key={item.id} /></a>)
      case 'telegram':
        return (<a key={item.id} href={item.href}><Icons.Social.SocialIconTelegram key={item.id} /></a>)
      case 'website':
        return (<a key={item.id} href={item.href}><Icons.Social.SocialIconSite key={item.id} /></a>)
      case 'facebook':
        return (<a key={item.id} href={item.href}><Icons.Social.SocialIconInstagram key={item.id} /></a>)
      default:
        return (<a key={item.id} href={item.href}><Icons.Social.SocialIconSite key={item.id} /></a>)
    }
  })

  return (
    <React.Fragment key={'fragment'}>
      <HeaderInfoWrap key={'HeaderInfoWrap-afishi'}>
        <CardInfo key={'CardInfoWrap-afishi'}>
          <Logos.MiskLogo />
          <CardTextShop>
            <CardTitle key={`CardText-name`}>{promoInfo.name}</CardTitle>
            <CardText key={`CardText-address`}>{promoInfo.address}</CardText>
            <CardText key={`CardText-worktime`}>{promoInfo.workTime}</CardText>
            <CardText key={`CardText-phone`}>{promoInfo.phone}</CardText>
          </CardTextShop>
        </CardInfo>
        <CardInfo key={'CardInfoWrap-af'}>
          <CardTextArea key={`CardTextArea-contact`}>
            <CardText>Контакты</CardText>
            <CardTitle key={`CardTexttitele-contact`}>
              {contacts.map(c => <SocialIcon key={c.id} item={c} />)}
              
            </CardTitle>
          </CardTextArea>
        </CardInfo>
        {features && features.map((f, ind) => (<CardInfo key={`CardIn-${ind}`}>
          <CardTextArea key={`CardTextArea-${ind}`}>
            <CardText key={`CardText-${ind}`}>{f.name}</CardText>
            <CardTitle key={`CardTitle-${ind}`}>{f.value}</CardTitle>

          </CardTextArea>
        </CardInfo>))}
          
      </HeaderInfoWrap>
    </React.Fragment>)
}

export default HeaderInfo;

const HeaderInfoWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* Frame 427322078 */
  align-items: center;
  box-sizing: border-box;

  width: 1366px;
  height: 162px;

  /* Текст/#FFFFFF текст|иконки|кнопки */
  background: #FFFFFF;
  /* #D6D6D6 графики */
  border-width: 0px 0.5px 0.5px 0.5px;
  border-style: solid;
  border-color: #D6D6D6;
  box-shadow: 0px 2px 15.2px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  padding: 19px 20px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

  /* padding: 16px;
  background: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
  border-radius: 5px; */
  /* gap: 60px; */
`

const CardInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* width: 170px;
  height: 63px; */
`

const CardTextShop = styled.div`
  display: flex;
  flex-direction: column;
  /* Frame 195 */
  /* Frame 427322084 */
  padding: 12px 17px;
  width: 240px;

  /* Текст/#FFFFFF текст|иконки|кнопки */
  background: #FFFFFF;
`
const CardTextArea = styled.div`
  display: flex;
  flex-direction: column;
  /* Frame 195 */
  /* Frame 427322084 */
  padding: 12px 17px;
  width: 200px;
  height: 92px;

  /* Текст/#FFFFFF текст|иконки|кнопки */
  background: #FFFFFF;
  box-shadow: 0px 2px 15.2px rgba(0, 0, 0, 0.11);
  border-radius: 5px;

  /* Inside auto layout */
  flex: none;
  order: 3;
  flex-grow: 0;

`

const CardTitle = styled.div`
  /* За сегодня */

  align-items: center;

  /* Текст/#333333 текст|кнопки */
  color: #333333;
  /* За сегодня */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 44px;
  /* identical to box height, or 202% */
  display: flex;
  align-items: center;
  color: #333333;

  & svg {
    margin-right: 6px;
  }
`

const CardText = styled.div`
  height: 28px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  align-items: center;
  color: #8F8F8F;
`