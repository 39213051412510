import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components"

import { Icons } from "../../../assets";
import { bool, boolean } from "yup";

interface IProps {
  options: any[];
  setSelectValue: any
  value: any;
  showError: boolean;
}

const UniversalSelector = (props: IProps) => {
  const { options, setSelectValue, value, showError } = props
    
  const [switchIsOpen, setSwitchIsOpen] = useState(false)

  const dropdownRef = useRef<HTMLDivElement>(null);

  const swithHandler = (key: any) => {
    if (!value || key.value !== value.value) {
      setSelectValue(key)
    }
    setSwitchIsOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSwitchIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (<SelectorWrap switch={switchIsOpen} $hasError={!!showError} $hasOpen={switchIsOpen}>
    <TitleLabel onClick={() => { setSwitchIsOpen(!switchIsOpen) }}>
      <span>{(value) ? value.name : 'Выберите локацию'}</span>
    </TitleLabel>
    <NoiseSelectorBtn>
      {switchIsOpen ? <Icons.IconArrowUp /> : <Icons.IconArrowDown />}
    </NoiseSelectorBtn>
    {switchIsOpen && (
      <SelectorDropdown ref={dropdownRef}>
        {options?.map((item) => {
          return (false) //item.value === value.value) 
            ? null
            : (
              
              <Title key={item.key} onClick={() => swithHandler(item)}>
                <span>{item.name}</span>
              </Title>
            )
        })}
      </SelectorDropdown>
    )}
  </SelectorWrap>)
}

export default UniversalSelector;

const Title = styled.div`
  /* Frame 427322058 */

  /* box-sizing: border-box; */

  /* За сегодня */
  padding: 0 17px 0 18px;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 34px;
  /* identical to box height, or 112% */
  display: flex;
  align-items: center;
  color: #333333;
  width: 100%;
  height: 38px;
  /* #8F8F8F 30% */
  /* Подтвержден */

  justify-content: flex-start;
  text-align: center;
  z-index: 1880;
  /* Текст/#333333 текст|кнопки */
  color: #333333;

  & span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    color: #333333;
    z-index: 1880;
  }
`

const TitleLabel = styled.div`
  /* Frame 427322058 */

  /* box-sizing: border-box; */

  /* За сегодня */
  padding: 0 17px 0 18px;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 34px;
  /* identical to box height, or 112% */
  display: flex;
  align-items: center;
  color: #333333;
  width: 100%;
  height: 38px;
  /* #8F8F8F 30% */
  /* Подтвержден */

  justify-content: flex-start;
  text-align: center;
  z-index: 0;
  /* Текст/#333333 текст|кнопки */
  color: #333333;

  & span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    color: #333333;
  }
`

const SelectorDropdown = styled.div`
  width: 100%;
  max-height: 280px;
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
  position: absolute;
  /* top: 20px; */
  justify-content: center;
  /* padding-left: 3px; */
  padding-top: 2px;
  align-items: center;
  /* top: 30px; */
  display: flex;
  flex-direction: column;
  background-color: #fff;
  /* border: 1px solid rgba(143, 143, 143, 0.3);
  border-top: none;
  border-radius: 0 0 8px 8px; */
  border-radius: 7px;
  overflow-y: auto;
  box-shadow: 0px 0px 8px 0px rgb(158 158 158 / 40%);
  z-index: 1800;
  ::-webkit-scrollbar {
    width: 14px;
    /* border-radius: 8px; */
    background-color: inherit;
    z-index: 1800;
  }

  ::-webkit-scrollbar-thumb {
    height: 140px;
    background-color: #E7E7E7;
    /* border-radius: 8px;
    border: 5px solid #fff; */
    z-index: 1800;
  }
`

const NoiseSelectorBtn = styled.div`
  position: absolute;
  right: 6px;
  top: 4px;
  & svg path {
    fill: #A7A7A7;
  }
`

const SelectorWrap = styled.div<{
  switch: boolean;
  $hasOpen: boolean;
  $hasError?: boolean;
}>`
  /* Frame 195 */
  margin-left: 20px;
  width: 400px;
  height: 38px;
  justify-content: space-between;
  /* Текст/#FFFFFF текст|иконки|кнопки */
  background: #FFFFFF;
  box-shadow: ${(props) => props.switch ? '0px 2px 15.2px rgba(0, 0, 0, 0.11)' : 'none'};
  /* border-top: ${(props) => props.switch ? '1px solid #D6DCE9' : 'none'};  ;
  border-left: ${(props) => props.switch ? '1px solid #D6DCE9' : 'none'};  ;
  border-right: ${(props) => props.switch ? '1px solid #D6DCE9' : 'none'};  ; */
  border-radius: 8px 8px ${props => props.$hasOpen ? '0px 0px' : '8px 8px'};
  border: 1px solid ${props => props.$hasError ? 'red' : '#D6DCE9'};
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 13px;
  line-height: 26px;
  /* letter-spacing: 0.02em; */
  color: #1F1E24;
  /* text-transform: capitalize; */
  /* z-index: 3; */
  cursor: pointer;

`
