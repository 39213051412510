import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import styled from 'styled-components';

Chart.register(ArcElement, Tooltip, Legend);

const PienutChart = ({ value }) => {
  const data = {
    datasets: [
      {
        data: [859, 1209, 433],
        backgroundColor: ['#2B1ECA', '#DD1A6A', '#FBB500'], // Цвета для заполненной и незаполненной части
        borderWidth: 0, // Убираем границы
        borderRadius: 2,
      },
    ],
  };

const options = {
  cutout: '80%', // Размер внутреннего радиуса для создания эффекта кольца
  rotation: -45, // Начальная точка вращения
  circumference: 360, // Угол, который будет покрыт (180 градусов для полукруга)
  plugins: {
    tooltip: {
      enabled: false, // Отключаем всплывающие подсказки
    },
    legend: {
    display: false, // Отключаем легенду
    },
  },
};

return <div style={{position: 'relative'}}>
    <Doughnut data={data} options={options} width={214} height={214} />
    <StyledInfoWrap>
      <StyledInfoHeader>Общее число</StyledInfoHeader>
      <StyledInfoText>{value}</StyledInfoText>
    </StyledInfoWrap>
  </div>
};

export default PienutChart;

const StyledInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);

`

const StyledInfoHeader = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.01em;

  /* Текст/#333333 текст|кнопки */
  color: #333333;

`
const StyledInfoText = styled.div`
/* 3 510 */

  width: 67px;
  height: 28px;

  /* Заголовок */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  /* identical to box height, or 112% */
  display: flex;
  align-items: center;

  /* Текст/#333333 текст|кнопки */
  color: #333333;


  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;


`