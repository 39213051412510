import styled from 'styled-components'
import RadialBarChart from './RadialBar';

const FrameSex = () => {
  return (
    <StyledWrapper>
              <StyledCard>
                <RadialBarChart percentage={14} colorDut={'#2B1ECA'} add={14} text={'Мужчины'}/>
                <StyledInfoWrap>
                  <StyledInfoItem>
                    <StyledIndicator $color={'#DDDDDD'}></StyledIndicator>
                    <StyledTextWrap>
                      <StyledHeader>{'Общее число'}</StyledHeader>
                      <StyledText>{23}<span>{-10.39}%</span></StyledText>
                    </StyledTextWrap>
                  </StyledInfoItem>
                  <StyledInfoItem>
                    <StyledIndicator $color={'#2B1ECA'}></StyledIndicator>
                    <StyledTextWrap>
                      <StyledHeader>{'LTV (%)'}</StyledHeader>
                      <StyledText>{'14%'}<span>{-2.14}%</span></StyledText>
                    </StyledTextWrap>
                  </StyledInfoItem>
                </StyledInfoWrap>
                
              </StyledCard>
              <StyledCard>
                <RadialBarChart percentage={22} colorDut={'#DD1A6A'} add={14} text={'Женщины'}/>
                <StyledInfoWrap>
                  <StyledInfoItem>
                    <StyledIndicator $color={'#DDDDDD'}></StyledIndicator>
                    <StyledTextWrap>
                      <StyledHeader>{'Общее число'}</StyledHeader>
                      <StyledText>{23}<span>{-10.39}%</span></StyledText>
                    </StyledTextWrap>
                  </StyledInfoItem>
                  <StyledInfoItem>
                    <StyledIndicator $color={'#DD1A6A'}></StyledIndicator>
                    <StyledTextWrap>
                      <StyledHeader>{'LTV (%)'}</StyledHeader>
                      <StyledText>{'14%'}<span>{-2.14}%</span></StyledText>
                    </StyledTextWrap>
                  </StyledInfoItem>
                </StyledInfoWrap>
              </StyledCard>
            </StyledWrapper>
  )
}

export default FrameSex;

const StyledInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const StyledInfoItem = styled.div`
  display: flex;
  flex-direction: raw;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  width: 170px;
`
const StyledIndicator = styled.div<{
  $color: string;
}>`
  background: ${({ $color }) => ($color) ? $color : '#ffffff'};
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 17px;
`
const StyledTextWrap = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledHeader = styled.div``
const StyledText = styled.div`
  width: 28px;
  height: 23px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  /* identical to box height, or 164% */
  display: flex;
  align-items: center;
  color: #333333;

  & span {
    margin-left: 17px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    color: #CA281E;
  }
`
const StyledCard = styled.div`
  display: flex;
  flex-direction: raw;
  justify-content: space-between;
  width: 400px;
  height: 214px;
  align-items: center;
`
const StyledWrapper = styled.div`
  display: flex;
  padding: 85px 200px;
  flex-direction: raw;
  justify-content: space-between;
`