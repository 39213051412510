import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components'
import { useApplication } from '../../context/ApplicationContext';
import useGetData from '../../hooks/useGetData';
import { IUser } from '../../types';

const AccountPage = () => {
  const [userData, setUserData] = useState<IUser>()

  const url = `${process.env.REACT_APP_API_URL}/auth/info`;
  const { data, loading } = useGetData({ url });
  // const { user } = data
  
  useEffect(() => {
    if (data) {
      setUserData(data.user)
    }
  }, [data])

  return (
    <FormContainer>
      <FormItemContainer>
        <Label>
          Username
        </Label>
        <Input
          type="text"
          name="username"
          value={(userData)?userData.login: ''}
        />
      </FormItemContainer>
      <FormItemContainer>
        <Label>
          Firstname
        </Label>
        <Input
          type="text"
          name="firstname"
          value={(userData)?userData.firstname: ''}
        />
      </FormItemContainer>
      <FormItemContainer>
        <Label>
          Lastname
        </Label>
        <Input
          type="text"
          name="lastname"
          value={(userData)?userData.lastname: ''}
        />
      </FormItemContainer>
      <FormItemContainer>
        <Label>
          Email
        </Label>
        <Input
          type="text"
          name="email"
          value={(userData)?userData.email: ''}
        />
      </FormItemContainer>
      <FormItemContainer>
        <Label>
          ID in Database
        </Label>
        <Input
          type="text"
          name="id"
          value={(userData)?userData.id: ''}
        />
      </FormItemContainer>
      <FormItemContainer>
        <Label>
          Phone
        </Label>
        <Input
          type="text"
          name="phone"
          value={(userData)?userData.phone: ''}
        />
      </FormItemContainer>
      <FormItemContainer>
        <Label>
          Username
        </Label>
        <Input
          type="text"
          name="username"
          value={(userData)?userData.login: ''}
        />
      </FormItemContainer>
      <FormItemContainer>
        <Label>
          Title
        </Label>
        <Input
          type="text"
          name="title"
          value={(userData)?userData.title: ''}
        />
      </FormItemContainer>
      <FormItemContainer>
        <Label>
          State
        </Label>
        <Input
          type="text"
          name="state"
          value={(userData)?userData.state: ''}
        />
      </FormItemContainer>
      <FormItemContainer>
        <Label>
          Type
        </Label>
        <Input
          type="text"
          name="type"
          value={(userData)?userData.type: ''}
        />
      </FormItemContainer>
    </FormContainer>

   
  )
}

export default AccountPage;

const FormContainer = styled.div`
  margin: 0 auto;
  padding: 50px;
  width: 100%;
  display: flex;
  gap: 40px;
  flex-direction: column;
  border: 2 red solid
`

const FormItemContainer = styled.div<{
  password?: boolean
}>`
  position: relative;
  & svg { 
    position: absolute;
    right: 17px;
    top: 15px;
    cursor: pointer;
  }

  ${({ password }) => password && css`
    display: flex;
    gap: 10px;
  `}

`

const Input = styled.input<{
  password?: boolean
}>`
  position: relative;
  width: 100%;
  height: 44px;
  padding: 0 17px 0 18px;
  border-radius: 8px;
  color: #1F1E24;
  border: 1px solid #D6DCE9;
  font-weight: 600;
  font-size: 13px;
  line-height: 26px;
  z-index: 0;

  ${({ password }) => password && css`
    width: 156px;
  `}

  &:focus {
    border: 1px solid #E65E20;
    border-color: #E65E20;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &:hover {
    border: 1px solid #E65E20;
    border-color: #E65E20;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  &:-webkit-autofill {
    -webkit-transition: 'background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
  }

  ::placeholder { 
    color: #A5A5A5;
  }

  :-ms-input-placeholder { 
    color: #A5A5A5;
  }

  ::-ms-input-placeholder { 
    color: #A5A5A5;
  }

  &:invalid {
    border: 1px solid #E65E20;
  } 
`

const Label = styled.label`
  position: absolute;
  top: -28px;
  font-weight: 600;
  font-size: 11px !important;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #000;

  & span {
    color: #E65E20;
    margin-left: 4px;
  }
`
