import styled from 'styled-components'

const columnsAttendanceTable = [
  {
      title: 'Возраст',
      dataIndex: 'age',
      width: 150,
  },
  {
      title: 'Мужчины',
      dataIndex: 'male',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}</StyledSum> ,
  },
  {
      title: 'Мужчины %',
      dataIndex: 'dMale',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0} %</StyledSum> ,
  },
  {
      title: 'Женщины',
      dataIndex: 'female',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}</StyledSum> ,
  },
  {
      title: 'Женщины %',
      dataIndex: 'dFemale',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0} %</StyledSum> ,
  },
  {
      title: 'Всего',
      dataIndex: 'human',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}</StyledSum> ,
  },
    
]

const columnsLTVTable = [
  {
      title: 'Возраст',
      dataIndex: 'age',
      width: 150,
  },
  {
      title: 'Мужчины',
      dataIndex: 'man',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}</StyledSum> ,
  },
  {
      title: 'LTV %',
      dataIndex: 'ltv_man',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}%</StyledSum> ,
  },
  {
      title: 'Женщины',
      dataIndex: 'woman',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}</StyledSum> ,
  },
  {
      title: 'LTV %',
      dataIndex: 'ltv_woman',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}%</StyledSum> ,
  },
  {
      title: 'Всего',
      dataIndex: 'all',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}</StyledSum> ,
  },
  {
    title: 'LTV %',
    dataIndex: 'ltv_all',
    width: 30,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}%</StyledSum> ,
    },
    
]

const columnsZoneAnaliticsTable = [
  {
      title: 'Время',
      dataIndex: 'time',
      width: 150,
  },
  {
      title: 'Приход',
      dataIndex: 'human',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}</StyledSum> ,
  },
  {
      title: 'Уход',
      dataIndex: 'uhod',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}</StyledSum> ,
  },
  {
      title: 'В помещении',
      dataIndex: 'inhall',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}</StyledSum> ,
  },
  {
      title: 'Динамика',
      dataIndex: 'dynamik',
      width: 30,
      render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0} %</StyledSum> ,
  },
    
]


export {
  columnsAttendanceTable,
  columnsLTVTable,
  columnsZoneAnaliticsTable,
}
  
const StyledSum = styled.div`
  text-align: right;
  padding-right: 20%;
`
