import { CSSProperties, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components'
import moment from 'moment';
import dayjs from 'dayjs';
import { PuffLoader } from 'react-spinners';
import { DatePicker } from 'antd'

import { Icons } from '../../assets';

import { LineChart } from '../rkeeper/components/LineChart';
import AttendanceTable from './components/AttendanceTable';

const { RangePicker } = DatePicker;

moment().format();

const AttendancePage = () => {
  const [data, setData] = useState<any>([])
  const [dataTable, setDataTable] = useState<any>([])
  const [dataChartMemo, setDataChartMemo] = useState<any>([])
  const [dataChart, setDataChart] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [period, setPeriod] = useState({
    from: moment().startOf('month').format('YYYY-MM-DD').valueOf(),
    to: moment().format('YYYY-MM-DD').valueOf(),
  })

  const urlForRemains = useMemo(() => `${process.env.REACT_APP_API_URL}/shops/1/reports/cctv/totals?from=${period.from}&to=${period.to}`, [period])
  
  useEffect(() => {
    const range = dataChartMemo.map(i => i.date)
    const chart = {
        range: range,
        values: [
          { key: 'Женщины', range: range, values: dataChartMemo.map(item => item.female) },
          { key: 'Мужчины', range: range, values: dataChartMemo.map(item => item.male) },
        ] 
    }
    setDataChart(chart)
  }, [dataChartMemo])
  
  useEffect(() => {
    Promise.all([
        fetch(urlForRemains, {
          // credentials: 'include',
          headers: {
            'accept': 'application/json',
            'Authorization': `${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json',

          }
        })
          .then(res => res.json())
          .catch(() => []),
    ])
      .then(data => {
        setData(data[0])
        setLoading(false)
      })
  }, [urlForRemains])

  useEffect(() => {
    if (data) {
      const convertedData = data.map(i => ({
        // hour: moment(i.datetime).format('HH'),
        // hourE: moment(i.datetime).add(1, 'hour').format('HH'),
        date: moment(i.datetime).format('YYYY-MM-DD'),
        datetime: i.datetime,
        age: i.age,
        female: i.female,
        male: i.male,
        human: i.male + i.female,
      }))
      
      const summedDataPerDay = convertedData.reduce((acc, curr) => {
        const existing = acc.find(item => item.date === curr.date);
        if (existing) {
          // existing.time = `${existing.hour}:00-${existing.hourE}:00`
          existing.female += curr.female;
          existing.male += curr.male;
          existing.human += curr.human;
        } else {
          acc.push({ ...curr });
        }
        return acc;
      }, []);

      const summedData = convertedData.reduce((acc, curr) => {
        const existing = acc.find(item => item.age === curr.age);
        if (existing) {
          // existing.time = `${existing.hour}:00-${existing.hourE}:00`
          existing.female += curr.female;
          existing.male += curr.male;
          existing.human += curr.human;
          existing.dFemale = Math.round(existing.female / existing.human * 100)
          existing.dMale = Math.round(existing.male / existing.human * 100)
        } else {
          acc.push({ ...curr });
        }
        return acc;
      }, []);

      setDataChartMemo(summedDataPerDay)
      setDataTable(summedData)
    }
  }, [data])

  const onDateChange = (date: any, dateString: [string, string]) => {
    setPeriod({
      from: dateString[0],
      to: dateString[1],
    })
  }

  const cssOverride: CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
  }

  return (
      <Wrapper>
        <Header>
        <StyledHeadTitle>Посещаемость</StyledHeadTitle>
        <StyledCaledarWrapper>
          <StyledCalendarMemo>
            <Icons.IconSlideTrendFill />
            Сравнение периодов
          </StyledCalendarMemo>
          <StyledRangePicker
            suffixIcon={<Icons.IconMainCalendar />}
            value={[dayjs(period.from), dayjs(period.to)]}
            format={['YYYY-MM-DD', 'YYYY-MM-DD']}
            onChange={onDateChange}
          />
        </StyledCaledarWrapper>
      </Header>
      {loading ? (
        <PuffLoader color="#E65E20" cssOverride={cssOverride} />
      ) : (
        <div>
          {dataChart && (<ChartsWrapper>
            <LineChart dataSource={dataChart} />
          </ChartsWrapper>)}
          <AttendanceTable
            dataValues={dataTable}
            loading={loading}
          />
          
        </div>
      )}
    </Wrapper>

   
  )
}

export default AttendancePage;

const Wrapper = styled.div`
  min-width: 100%;
  height: 80%h;
  background-color: #F7F7F7;
  padding: 16px 29px 16px 0px;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

const StyledRangePicker = styled(RangePicker)`
  width: 250px;
  color: #ffffff;
  
`

const StyledHeadTitle = styled.label`
  font-family: 'Inter';
  font-size: 25px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #333333;
`

const StyledCaledarWrapper = styled.div`
  display: flex;
  gap: 10px;
  color: #ffffff;
  
  & svg {
    width: 20px;
    height: 20px;
  }
  .ant-picker {
    background-color: #A7388E;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
  }

  .ant-picker .ant-picker-input,
  .ant-picker .ant-picker-range,
  .ant-picker .ant-picker-input-active .ant-picker-input-placeholder {
    display: flex;
    flex-direction: row-reverse;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #ffffff;

  }

  .ant-picker .ant-picker-input .ant-picker-input-active {
    color: #ffffff !important;
  }

  .ant-picker .ant-picker-input .ant-picker-input-placeholder {
    color: #ffffff !important;
  }

  .ant-picker .ant-picker-input span.ant-picker-suffix,
  .ant-picker.ant-picker-range span.ant-picker-suffix {
    order: -1;
    margin-left: 0;
    margin-right: 10px; /* Для отступа иконки от текста */
    color: #ffffff;
  }

  .ant-picker.ant-picker-range.ant-picker-range-separator {
    color: #ffffff !important;
    /* display: none; */
  }

  .ant-picker-range-separator > span {
    color: #ffffff !important; /* Замените #ваш_цвет на нужный код цвета */
  }
  
  .ant-picker-clear {
    color: #ffffff !important; /* Замените #ваш_цвет на нужный код цвета */
  }
  
  .ant-picker .ant-picker-clear {
    position: absolute;
    right: 217px;
    /* color: #ffffff !important; */
  }


`

const StyledCalendarMemo = styled.div`
  display: flex;
  width: 220px;
  height: 40px;
  gap: 10px;
  background-color: #E9E9E9;
  border-radius: 5px 0px 0px 0px;
  opacity: 0px;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #333333;

`

const ChartsWrapper = styled.div`
  height: 470px;
  width: 100%;
  margin-bottom: 40px;
  padding: 30px;
  background-color: #fff;
  border-radius: 5px;
`
