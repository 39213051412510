import { useState, useMemo } from 'react';
import styled from 'styled-components'
import { DatePicker } from 'antd'
import moment from 'moment';
import dayjs from 'dayjs';
import NavbarTabsModal from '../crm/components/NavbarTabsModal';
import { useApplication } from '../../context/ApplicationContext';
import useAuth from '../../hooks/useAuth';

import Plag0 from './plagins/Plag0';
import Plag1 from './plagins/Plag1';
import Plag2 from './plagins/Plag2';
import Plag3 from './plagins/Plag3';
import Plag4 from './plagins/Plag4';
import Plag5 from './plagins/Plag5';

const { RangePicker } = DatePicker;

const DATE_FORMAT = 'YYYY-MM-DD'
const CALENDAR_FORMAT = 'DD.MM.YYYY'

interface PropsRkeeper {
  sidemenu?: any;
}

const DashboardPage = ({ sidemenu }: PropsRkeeper) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { dateRange, setDateRange } = useApplication()
  
  const from = useMemo(() => dateRange && dateRange.from ? dateRange.from : moment().utc().format(DATE_FORMAT).valueOf(), [dateRange])
  const to = useMemo(() => dateRange && dateRange.to ? dateRange.to : moment().utc().format(DATE_FORMAT).valueOf(), [dateRange])

  const onDateChange = (date: any, dateString: [string, string]) => {
    if (date) {
      setDateRange({
        from: dayjs(dateString[0]).format(DATE_FORMAT).valueOf(),
        to: dayjs(dateString[1]).format(DATE_FORMAT).valueOf(),
      })
      
      
    } else {
      // setHistoryDateRange([])
    }
  }

  return (
    // <Layout sidemenu={ sidemenu }>
      <Wrapper>
        <Header>
          <WrapCalendar>
            <NavbarTabsModal
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
            />
            <StyledRangePicker
                value={[dayjs(from), dayjs(to)]}
                format={[DATE_FORMAT, DATE_FORMAT]}
                onChange={onDateChange}
              />
          </WrapCalendar>
        </Header>
        <Container>
          <WidgetWrap width="66%"><Widget><Plag0 /></Widget></WidgetWrap>
          <WidgetWrap width="33%"><Widget><Plag1 /></Widget></WidgetWrap>
          <WidgetWrap width="33%"><Widget><Plag2 /></Widget></WidgetWrap>
          <WidgetWrap width="66%"><Widget><Plag3 /></Widget></WidgetWrap>
          <WidgetWrap width="66%"><Widget><Plag4 /></Widget></WidgetWrap>
          <WidgetWrap width="33%"><Widget><Plag5 /></Widget></WidgetWrap>
        </Container>
      </Wrapper>

    // </Layout>
  )
}

export default DashboardPage;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  object-fit: cover; /* Изображение заполняет контейнер, сохраняя пропорции */
`
const Widget = styled.div`
  /* background-color: #FFFFFF; Цвет фона для наглядности */
  height: 400px; /* Высота виджета */
  padding: 0px;
`
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
`;

const WidgetWrap = styled.div<{
  width: string;
}>`
   /* Отступ между рядами */
  background-color: #FFFFFF;
  padding: 10px;
  height: 400px; /* Высота виджета */
  width: ${(props) => props.width || '50%'};
`;

const Wrapper = styled.div`
  min-width: 100%;
  height: calc(100% - 110px);
  background-color: #F7F7F7;
  padding: 0px;
  background: #F7F7F7;

`

const Header = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 81px;
  margin-bottom: 10px;
  /* Текст/#FFFFFF текст|иконки|кнопки */
  background: #FFFFFF;
  /* #D6D6D6 графики */
  border-width: 0px 0.5px 0.5px 0.5px;
  border-style: solid;
  border-color: #D6D6D6;
  box-shadow: 0px 2px 15.2px rgba(0, 0, 0, 0.11);
  border-radius: 0px 0px 5px 5px;
  padding: 22px 17px 21px 21px;
`

const WrapCalendar = styled.div`
  display: flex;
  flex-direction: row;
  width: 770px;
  height: 38px;
  padding: 5px 15px;
  /* Таблицы/#EEEFEF формы|таблицы */
  background: #EEEFEF;
  border-radius: 5px;
`

const StyledRangePicker = styled(RangePicker)`
  width: 250px;
  margin-left: 20px;
  /* color: #ffffff; */
  
`
