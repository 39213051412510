import { Icons } from '../assets';

const { HeaderIcons } = Icons;

export const navigationItems = {
  navbar: [
    {
      name: 'Дашборд ',
      to: '/dashboard', 
      icon: <HeaderIcons.IconHeaderRkeeper />,
      text: 'Дашборд',
      height: 195,
    },
    {
      name: 'RKeeper ',
      to: '/rkeeper', 
      icon: <HeaderIcons.IconHeaderRkeeper />,
      text: 'rkeeper',
      height: 195,
    },
    {
      name: 'Бронирование',
      to: '/booking', 
      icon: <HeaderIcons.IconHeaderBooking />,
      text: 'booking',
      height: 195,
    },
    {
      name: 'CRM ',
      to: '/crm', 
      icon: <HeaderIcons.IconHeaderCrm />,
      text: 'crm',
      height: 230,
    },
    {
      name: 'Видеоаналитика',
      to: '/video', 
      icon: <HeaderIcons.IconHeaderVideo />,
      text: 'video',
      height: 90,
    },
    {
      name: 'Настройки',
      to: '/settings', 
      icon: <HeaderIcons.IconHeaderSetting />,
      text: 'settings',
      height: 90,
    },
    
  ], 
  rkeeper: [
    {
      name: 'Продажи ',
      desc: 'Продажи',
      icon: <Icons.IconCalendarClockFill />,
      to: '/rkeeper/viruchka', 
      text: 'rkeeper_viruchka',
      subs: [{
        name: 'Выручка ',
        to: '/rkeeper/viruchka', 
        text: 'rkeeper_viruchka',
      }, {
        name: 'Динамика ',
        to: '/rkeeper/dynamic', 
        text: 'rkeeper_dynamic',
      }]
    },
    {
      name: 'Товары ',
      desc: 'Товары ',
      icon: <Icons.IconCalendarClockFill />,
      to: '/rkeeper/menu', 
      text: 'rkeeper_menu',
      subs: [{
        name: 'Меню ',
        to: '/rkeeper/menu', 
        text: 'rkeeper_menu',
      }, {
        name: 'ТОП-позиции ',
        to: '/rkeeper/toppositions', 
        text: 'rkeeper_toppositions',
      }, {
        name: 'СТОП-позиции ',
        to: '/rkeeper/stoppositions', 
        text: 'rkeeper_stoppositions',
      }, {
        name: 'Анализ продаж ',
        to: '/rkeeper/dynamic', 
        text: 'rkeeper_dynamic',
      }]
    },
     {
      name: 'Склад ',
      desc: 'Склад ',
      icon: <Icons.IconCalendarClockFill />,
      to: '/rkeeper/menu', 
      text: 'rkeeper_menu',
      subs: [{
        name: 'Остатки ',
        to: '/rkeeper/ostatki', 
        text: 'rkeeper_ostatki',
      }, {
        name: 'Инвентаризации ',
        to: '/rkeeper/ostatki', 
        text: 'rkeeper_ostatki',
      }, {
        name: 'Анализ себестоимости ',
        to: '/rkeeper/ostatki', 
        text: 'rkeeper_ostatki',
      }]
    },
  ],
  booking: [
    {
      name: 'Бронирование ',
      desc: 'Бронирование ',
      icon: <Icons.IconCalendarClockFill />,
      to: '/booking/reservation', 
      text: 'booking_reservation',
      subs: [{
        name: 'Бронирование ',
        to: '/booking/reservation', 
        text: 'booking_reservation',
      }]
    },
    {
      name: 'Статистика ',
      desc: 'Статистика ',
      icon: <Icons.IconCalendarClockFill />,
      to: '/booking/bronirovanie', 
      text: 'booking_bronirovanie',
      subs: [{
        name: 'Отчет по бронированию ',
        to: '/booking/bronirovanie', 
        text: 'booking_bronirovanie_report',
      }, {
        name: 'Отчет по звонкам ',
        to: '/booking/callsreport', 
        text: 'booking_callsreport',
      }, {
        name: 'Отчет по промоутерам ',
        to: '/booking/promoreport', 
        text: 'booking_promoreport',
      }]
    },
    {
      name: 'Promo ',
      desc: 'Promo ',
      icon: <Icons.IconCalendarClockFill />,
      to: '/booking/bronirovanie', 
      text: 'booking_promo',
      subs: [{
        name: 'Афиши ',
        to: '/booking/afishi', 
        text: 'booking_afishi',
      }, {
        name: 'Схема зала ',
        to: '/booking/bronirovanie', 
        text: 'booking_hall',
      }, {
        name: 'Список промоутеров ',
        to: '/booking/promouters', 
        text: 'booking_promouters',
      }, {
        name: 'Реферальная программа ',
        to: '/booking/bronirovanie', 
        text: 'booking_ref',
      }]
    },
  ],
  crm: [
    {
      name: 'Клиенты ',
      desc: 'Клиенты ',
      icon: <Icons.IconCalendarClockFill />,
      to: '/crm/clients', 
      text: 'crm_clients',
      subs: [{
        name: 'Клиенты ',
        to: '/crm/clients', 
        text: 'crm_clients',
      }]
    },
    {
      name: 'Маркетинг ',
      desc: 'Маркетинг ',
      icon: <Icons.IconCalendarClockFill />,
      to: '/crm/marketing', 
      text: 'crm_marketing',
      subs: [
          // {
          // name: 'Дашборд ',
          // to: '/crm/marketing', 
          // text: 'crm_marketing',
          // },
          {
          name: 'Источники ',
          to: '/crm/clients_origins', 
          text: 'crm_clients_origins',
        },{
          name: 'Карты и макеты ',
          to: '/crm/clients_cards', 
          text: 'crm_clients_cards',
        },{
          name: 'Рассылки ',
          to: '/crm/clients_push', 
          text: 'crm_clients_push',
        },{
          name: 'Лояльность ',
          to: '/crm/clients_loyality', 
          text: 'crm_clients_loyality',
        },{
          name: 'Формы регистрации ',
          to: '/crm/reg_forms', 
          text: 'crm_reg_forms',
        },
      
      ]
    },
    {
      name: 'Статистика и отчеты ',
      desc: 'Статистика ',
      icon: <Icons.IconCalendarClockFill />,
      to: '/crm/statistics', 
      text: 'crm_statistics',
      subs: [{
        name: 'Посещения ',
        to: '/crm/clients', 
        text: 'crm_clients',
      },{
        name: 'Анализ продаж ',
        to: '/crm/clients_origins', 
        text: 'crm_clients_origins',
      },{
        name: 'Регистрации ',
        to: '/crm/clients_cards', 
        text: 'crm_clients_cards',
      },{
        name: 'Сегменты ',
        to: '/crm/clients_push', 
        text: 'crm_clients_push',
      },
      //   {
      //   name: 'Заказанные отчеты ',
      //   to: '/crm/reports', 
      //   text: 'crm_reports',
        // }
      ]
    },
    {
      name: 'Справочники ',
      desc: 'Справлчники ',
      icon: <Icons.HeaderIcons.IconHeaderCrm />,
      to: '/crm/helpers', 
      text: 'crm_helpers',
      subs: [{
        name: 'Теги для карт',
        to: '/crm/clients', 
        text: 'crm_clients',
      },{
        name: 'Пункт 2 ',
        to: '/crm/clients_origins', 
        text: 'crm_clients_origins',
      },{
        name: 'Пункт 3 ',
        to: '/crm/clients_cards', 
        text: 'crm_clients_cards',
      }]
    },
    
  ],
  video: [
    {
      name: 'Посещаемость ',
      desc: 'Посещаемость ',
      icon: <Icons.IconCalendarClockFill />,
      to: '/video/attendance', 
      text:'video_attendance'
    },
    // {
    //   name: 'Сегменты ',
    //   desc: 'Сегменты ',
    //   icon: <Icons.IconCalendarClockFill />,
    //   to: '/video/segments', 
    //   text:'video_segments'
    // },
    {
      name: 'Временной анализ',
      desc: 'Настройки ',
      icon: <Icons.IconCalendarClockFill />,
      to: '/video/zoneanalitics', 
      text:'video_zoneanalitics'
    },
    {
      name: 'LTV ',
      desc: 'LTV ',
      icon: <Icons.IconCalendarClockFill />,
      to: '/video/ltv', 
      text:'video_ltv'
    },
  ],
  settings: [
    {
      name: 'Аккаунт ',
      desc: 'Аккаунт ',
      icon: <Icons.IconCalendarClockFill />,
      to: '/settings/account', 
      text:'settings_account'
    },
    {
      name: 'Интеграция ',
      desc: 'Интеграция ',
      icon: <Icons.IconCalendarClockFill />,
      to: '/settings/integration', 
      text:'settings_integration'
    },
    {
      name: 'Точки продаж ',
      desc: 'Точки продаж ',
      icon: <Icons.IconCalendarClockFill />,
      to: '/settings/salepoints', 
      text:'settings_salepoints'
    },
    {
      name: 'Помощь ',
      desc: 'Помощь ',
      icon: <Icons.IconCalendarClockFill />,
      to: '/settings/help', 
      text:'settings_help'
    },
  ],
  footer: [], 
  


}
export default navigationItems