import React from 'react';
import Layout from '../../components/Layout';

interface PropsSettingsPage {
    sidemenu: any;
}

const  SettingsPage = ({sidemenu}: PropsSettingsPage) =>{
    return (
        <Layout sidemenu={sidemenu}>
            <div className='SettingsPage'>
            </div>

        </Layout>
    )
}

export default SettingsPage;