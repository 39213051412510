import React, { useState, useEffect } from "react";
import { CookiesProvider, useCookies } from 'react-cookie';
import MainRoutes from "./Routes";
import GlobalStyles from "./GlobalStyles";
import { ConfigProvider } from 'antd';
import ApplicationContext, { Theme } from "./context/ApplicationContext";
import SidebarsContext, { useSidebarsContext } from "./context/SidebarsContext";
import { IUser, IDateRangeType } from "./types";
import moment from './config/momentConfig';
import ruRU from 'antd/lib/locale/ru_RU';
import dayjs from "dayjs";

const DATE_FORMAT = 'YYYY-MM-DD'

export const getTodayDateRange = () => {
  return {
    from: moment().utc().add(-1, 'month').format(DATE_FORMAT).valueOf(),
    to: moment().utc().format(DATE_FORMAT).valueOf(),
  }
};

const App: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { pathname, search } = window.location
  const searchParams = new URLSearchParams(search);

  const [theme, setTheme] = useState<Theme>('light');
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [rangeStart, setRangeStart] = useState<string | undefined>(undefined);
  const [rangeEnd, setRangeEnd] = useState<string | undefined>(undefined);
  const [periodDate, setSetPeriodDate] = useState<string | undefined>(undefined);
  const [inventGroups, setInventGroups] = useState<any>(undefined);
  const [lastShift, setLastShift] = useState<string | undefined>(undefined)
  const [lastShiftLoad, setLastShiftLoad] = useState<boolean>(true)
  const [dateFilterMain, setDateFilterMain] = useState<string | undefined>(() => {
    if (searchParams.get('date')) {
      return moment(searchParams.get('date')).format(DATE_FORMAT).valueOf();
    }
    return moment().format(DATE_FORMAT).valueOf();
  })

  const [dateRange, setDateRange] = useState<IDateRangeType>(() => {
    // const dateFromParams = searchParams.get('date')
    // const isCurrentDay = moment().isSame(dateFromParams, 'day')
    // if (dateFromParams && !isCurrentDay) {
    //   return {
    //     from: moment.utc(dateFromParams).startOf('month').format(DATE_FORMAT)
    //       .valueOf(),
    //     to: moment().utc().format(DATE_FORMAT)
    //       .valueOf(),
    //   }
    // } if (dateFromParams && isCurrentDay) {
    //   return {
    //     from: moment.utc(dateFromParams).startOf('day').format(DATE_FORMAT)
    //       .valueOf(),
    //     to: moment().utc().add(3, 'hours').format(DATE_FORMAT).valueOf(),
    //   }
    // }
    return getTodayDateRange()
  });

  //! непрвильно - поменять - поэтому и не работало. пропустить через useState()
  const {
    isActiveSubmenu,
    setActiveSubmenu,
    numActiveSubmenu,
  } = useSidebarsContext()

  useEffect(() => {
    setInterval(async () => {
      // if (document.visibilityState === 'visible') {
      //   const userBrowserInfo = Bowser.parse(window.navigator.userAgent)
      fetch(`${process.env.REACT_APP_API_URL}/auth/info`, {
        // credentials: 'include',
        headers: {
          'accept': 'application/json',
          'Authorization': `${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',

        }
      })
        .then(resp => resp.json())
        .then(json => {
          const { token, user } = json
          const { accessToken } = token
          if (accessToken) localStorage.setItem('accessToken', accessToken)
          if (user) setUser(user)
          // setData(json);
          // setLoading(false);
          // setError(undefined);
        })
        .catch(err => {
          console.log('err', err);
          localStorage.removeItem("user")
          localStorage.removeItem("accessToken")
          localStorage.removeItem("refreshToken")
          setUser(undefined);
          removeCookie('id');
          window.location.href = '/login';
        });
      
    }, 40000)
    // }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    
    setUser(user);
  }, [user]);

  useEffect(() => {
    
    if (!lastShift && lastShiftLoad) fetch(`${process.env.REACT_APP_API_URL}/shops/1/last-closed-shift`, {
        // credentials: 'include',
        headers: {
          'accept': 'application/json',
          'Authorization': `${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',

        }
      })
        .then(resp => resp.json())
      .then(json => {
          const last = (json && json.SHIFTDATE) ? dayjs(json.SHIFTDATE).format('YYYY-MM-DD') : '2024-10-05'
          setLastShiftLoad(false)
          setLastShift(last)
        })

    
  }, [lastShift, lastShiftLoad])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookies, removeCookie] = useCookies<any['']>(['id']);
  
  return (
    <React.Fragment key='app'>
      <CookiesProvider>
        <ConfigProvider locale={ruRU}>
          <ApplicationContext.Provider
            value={{
              theme,
              setTheme: updateTheme => setTheme(updateTheme),
              user,
              setUser,
              onLogin: (updatedUser: any) => {
                setUser(updatedUser);
                
                window.location.href = '/';
              },
              onLogout: () => {
                setUser(undefined);
                removeCookie('id');
                window.location.href = '/login';
              },
              rangeStart,
              setRangeStart: (v: string | undefined) => setRangeStart(v),
              rangeEnd,
              setRangeEnd: (v: string | undefined) => setRangeEnd(v),
              periodDate,
              setSetPeriodDate: (v: string | undefined) => setSetPeriodDate(v),
              dateFilterMain,
              setDateFilterMain: (v: string | undefined) => setDateFilterMain(v),
              dateRange,
              setDateRange: (v: any) => setDateRange(v),
              inventGroups,
              setInventGroups: (v: any) => setInventGroups(v),
              lastShift,
              setLastShift: (v: any) => setLastShift(v),
            }}
          >
            <SidebarsContext.Provider
              value={{
                isActiveSubmenu,
                setActiveSubmenu: (v: boolean) => setActiveSubmenu(v),
                numActiveSubmenu,
                setNumActiveSubmenu: (v: any) => setActiveSubmenu(v),
              }}
            >
              <GlobalStyles/>
              <MainRoutes />

            </SidebarsContext.Provider>

          </ApplicationContext.Provider>
        </ConfigProvider>
      </CookiesProvider>
    </React.Fragment>
  )
}

export default App;
