import { styled } from 'styled-components'
import { Table, TableColumnsType } from 'antd';
import { columnsCallsReport } from './columns';

interface TableData {
  key: React.Key;
  SUM: number;
  CURRENCYTYPE?: string;
  CURRENCY?: string;
  ORIGINALSUN?: number;
  SHIFTDATE?: string;
  nested?: TableData[];
}

const columns: TableColumnsType<TableData> = columnsCallsReport;

const CallsReport = ({ dataValues, loading }) => {
  
  return (
    <StyledTable>
      <Table
        pagination={false}
        dataSource={dataValues}
        loading={loading}
        columns={columns}
        size="small"
        // tableLayout="fixed" // Устанавливаем фиксированный макет таблицы
        summary={(pageData) => {
          let sumSuccess = 0;
          let sumMissed = 0;
          let sumAutoanswer = 0;
          let sumAmount = 0;
          pageData.forEach(({ totalSuccess, totalMissed, totalAutoanswer, totalAmount }: any) => {
            sumSuccess += totalSuccess;
            sumMissed += totalMissed;
            sumAutoanswer += totalAutoanswer;
            sumAmount += totalAmount;
          });

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Всего</Table.Summary.Cell>
                <Table.Summary.Cell index={1}><StyledSum>{new Intl.NumberFormat('ru-RU').format(Number(sumSuccess))}</StyledSum></Table.Summary.Cell>
                <Table.Summary.Cell index={1}><StyledSum>{new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(Number(sumSuccess / sumAmount * 100))} %</StyledSum></Table.Summary.Cell>
                <Table.Summary.Cell index={2}><StyledSum>{new Intl.NumberFormat('ru-RU').format(Number(sumMissed))}</StyledSum></Table.Summary.Cell>
                <Table.Summary.Cell index={1}><StyledSum>{new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(Number(sumMissed / sumAmount * 100))} %</StyledSum></Table.Summary.Cell>
                <Table.Summary.Cell index={3}><StyledSum>{new Intl.NumberFormat('ru-RU').format(Number(sumAutoanswer))}</StyledSum></Table.Summary.Cell>
                <Table.Summary.Cell index={1}><StyledSum>{new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(Number(sumAutoanswer / sumAmount * 100))} %</StyledSum></Table.Summary.Cell>
                <Table.Summary.Cell index={4}><StyledSum>{new Intl.NumberFormat('ru-RU').format(Number(sumAmount))}</StyledSum></Table.Summary.Cell>
              </Table.Summary.Row>
            
            </>
          );
        }}
        rowClassName={() => 'main-row'}
      />
    </StyledTable>
	)
}

export default CallsReport;


const StyledTable = styled.div`
  .ant-table-thead > tr > th {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: center;
    padding: 0 !important; // Убираем внутренние отступы заголовков
    height: 50px; // Устанавливаем высоту строки заголовка
    line-height: 17px; // Устанавливаем высоту строки для текста заголовка

  }

  .ant-table-row-expand-icon-cell {
    width: 20px !important; // Устанавливаем фиксированную ширину для колонки значка раскрытия
  }

  .ant-table-expanded-row {
    & > td {
      padding-left: 30px !important; // Убираем отступ
      padding-right: 0 !important; // Убираем отступ
    }
  }
  .ant-table-layout-fixed {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .ant-table-footer {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;

  }

  
  table>tbody>tr>td{
    background-color: #ffffff;
    font-family: Inter;
    border: 1px solid #EFEFEF;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
    padding: 0 !important; // Убираем внутренние отступы ячеек
    height: 17px; // Устанавливаем высоту строки
    line-height: 17px; // Устанавливаем высоту строки для текста

  }

  .ant-table-summary {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;

  }

  
  
`

const StyledSum = styled.div`
  text-align: right;
  padding-right: 20%;
`
