import styled from 'styled-components'
import { Table, Image } from 'antd';
import type { TableColumnsType } from 'antd';

interface IPromouters {
  id: number,
  promo: string,
  commi: number,
  cntTables: number,
  profit: number,
  cleanProfit: number,
}

const columns: TableColumnsType = [
  {
    title: 'Промоутер',
    dataIndex: 'promo',
    width: 100,
    render: (i) => <PromoWrap>
       <StyledImage
        width={54}
        src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
      />
      <span>{i}</span>
    </PromoWrap>
  },
  {
    title: 'ID',
    dataIndex: 'id',
    width: 30,
    render: (i) => <IDWrap>{i}</IDWrap>
  },
  {
    title: 'Комиссия',
    dataIndex: 'commi',
    align: 'center',
    width: 40,
    render: (act) => <span>{act}%</span>
  },
  {
    title: 'Кол-во столов',
    dataIndex: 'cntTables',
    align: 'center',
    width: 60,
  },
  {
    title: 'Выручка',
    dataIndex: 'profit',
    width: 70,
    render: (o: any[]) => (
      <>
        {(o)
          ? <Sspan>{new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(o))}</Sspan>
          : <Nspan>{new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(0))}</Nspan>}
      </>
    )
  },
  {
    title: 'Чистый заработок',
    dataIndex: 'cleanProfit',
    width: 80,
    render: (o: any[]) => (
      <>
        {(o)
          ? <Sspan>{new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(o))}</Sspan>
          : <Nspan>{new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(0))}</Nspan>}
      </>
    )
  },
];

const sortedData: IPromouters[] = [
  {id: 310028, promo: 'Скорцова Светлана', commi: 15, cntTables: 12, profit: 78000, cleanProfit: 23000 },
  {id: 310028, promo: 'Скорцова Светлана', commi: 15, cntTables: 12, profit: 78000, cleanProfit: 23000 },
  {id: 310028, promo: 'Скорцова Светлана', commi: 15, cntTables: 12, profit: 78000, cleanProfit: 23000 },
  {id: 310028, promo: 'Скорцова Светлана', commi: 15, cntTables: 12, profit: 78000, cleanProfit: 23000 },
  {id: 310028, promo: 'Скорцова Светлана', commi: 15, cntTables: 12, profit: 78000, cleanProfit: 23000 },
  {id: 310028, promo: 'Скорцова Светлана', commi: 15, cntTables: 12, profit: 78000, cleanProfit: 23000 },
  
]

function Plag4() {
  

  return (
    <Palg3Wrap>
      <Header>Статистика по промоутерам</Header>
      <TableWrapper>
        <Table
          size='small'
          columns={columns}
          dataSource={sortedData}
          scroll={{ y: 240 }} // Устанавливаем высоту прокрутки
          sticky // Фиксируем заголовок
        />
      </TableWrapper>
    </Palg3Wrap>
  )
}

export default Plag4;

const StyledImage = styled(Image)`
  border-radius: 50%;
  margin-right: 8px;
`
const Palg3Wrap = styled.div`
  height: 380px; 
  width: 860px;
`

const PromoWrap = styled.label`
  display: flex;
  align-items: center;
  & span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    margin-left: 10px;
    /* identical to box height, or 107% */

  }
  
  

`
const IDWrap = styled.label`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  /* identical to box height, or 107% */
  display: flex;
  align-items: center;

  /* Текст/#A7388E текст|кнопки */
  color: #A7388E;

`

const Header = styled.div`
  margin-bottom: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  /* identical to box height, or 112% */
  display: flex;
  align-items: center;

  /* Текст/#333333 текст|кнопки */
  color: #333333;


`

const TableWrapper = styled.div`
  margin-top: 10px;
  .ant-table-thead {
    height: 20px; /* Вы можете установить желаемую высоту */
    line-height: 20px; /* Установите такое же значение, как и высота, для вертикального центрирования содержимого */
    /* Rectangle 91 */

    background: rgba(148, 129, 205, 0.25);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px 5px 0px 0px;
  }
  .ant-table-thead>tr>th::before {
    content: none !important;
  }
  .ant-table-cell {
    border-right: none !important; 
    border-left: none !important;/* Убираем вертикальные границы между ячейками */
  }

  .ant-table-row .ant-table-cell {
    height: 46px !important; 
    /* 79991233232 */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    align-items: center;

    /* Текст/#333333 текст|кнопки */
    color: #333333;

    & svg {
      margin-top: 9px;
    }
  }

  & td.leftAlign {
    text-align: left !important;
    padding-left: 5px !important;
  }

  & th.headerCenter {
    text-align: center !important;
  }
`

const Sspan = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  text-align: right;

  /* Текст/#333333 текст|кнопки */
  color: #3BCA1E;
`

const Nspan = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  text-align: right;

  /* Текст/#8F8F8F текст */
  color: #8F8F8F;


`
