import { useState, useEffect, useMemo } from 'react';
import { Chart, Filler } from 'chart.js';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import styled from 'styled-components'
import annotationPlugin from 'chartjs-plugin-annotation';
import { LineChart } from './LineChart';
import { calcSortedDataChart } from '../../rkeeper/components/consts';
import { useApplication } from '../../../context/ApplicationContext';

Chart.register(Filler, annotationPlugin);


function Plag0() {
  const { dateRange } = useApplication()

  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [chartData, setChartData] = useState<any>(undefined);

  const urlForRemains = useMemo(() => {
    const from = (dateRange && dateRange.from) ? dateRange.from : '2024-01-01'
    const to = (dateRange && dateRange.to) ? dateRange.to : '2024-11-01'
    const url = `${process.env.REACT_APP_API_URL}/shops/1/reports/revenue/station/days?from=${from}&to=${to}`
    
    return url

  }, [dateRange])
  
  useEffect(() => {
    Promise.all([
        fetch(urlForRemains, {
          // credentials: 'include',
          headers: {
            'accept': 'application/json',
            'Authorization': `${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json',

          }
        })
          .then(res => res.json())
          .catch(() => []),
    ])
      .then(data => {
        setData(data[0])
        setLoading(false)
      })
  }, [urlForRemains])
  
  useEffect(() => {
    if (data && Array.isArray(data)) { 
      setChartData(calcSortedDataChart(data.filter(i => i.CURRENCYTYPE !== 'Платежные карты')))
    } else {
      setChartData(undefined)
    }
    
  }, [data])

  return (
    <Palg1Wrap>
      <Header>Финансы</Header>
      {!loading && <LineChart dataSource={chartData} />}
      <style>{`
          .chartjs-render-monitor {
            position: relative;
          }
          .chartjs-render-monitor .tick {
            background-color: blue;
            color: white;
            padding: 2px 4px;
            border-radius: 3px;
          }
        `}
      </style>
    </Palg1Wrap>
  )
}

export default Plag0;

const Palg1Wrap = styled.div`
  height: 320px; 
  width: 880px;
`

const Header = styled.div`
  margin-bottom: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  /* identical to box height, or 112% */
  display: flex;
  align-items: center;

  /* Текст/#333333 текст|кнопки */
  color: #333333;


`
