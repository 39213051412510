import styled from 'styled-components'
import { Icons } from '../../../assets';
import Highlighter from 'react-highlight-words';
import { ManOutlined, WomanOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';
import StatusSelector from './StatusSelector';
import { Table } from 'antd';

const columnsMissedCallsReport = (formatedTargets: string[]) => {
  let columns: any = [
    {
      title: 'Дата',
      width: 300,
      render: (value, req, ind) => value.date,
    },
    {
      title: 'всего',
      width: 40,
      render: (value, req, ind) => value.total,
    }
  ]
  let chi: any = []
  for (let i = 0; i < formatedTargets.length; i++) {
    const el = formatedTargets[i];
    chi.push({
      title: el,
      width: 40,
      render: (value, req, ind) => {
        return (value[el] && value[el].missed) ? value[el].missed : null
      },
    })
  }
  chi.push({
    title: 'Автоотв.',
    width: 40,
    render: (value) => value.autoanswer,
  })
  chi.push({
    title: 'Всего',
    width: 40,
    render: (value) => (value && value.missed) ? value.missed: 0,
  })
  chi.push({
    title: 'Всего отд',
    width: 40,
    render: (value) => value.missed - value.autoanswer,
  })
  columns.push({
    title: 'Прорущенные',
    children: chi
  })
  columns.push({
    title: '% пропущенных',
    width: 60,
    render: (value) => new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(Number(value.missed / value.total * 100)),
  })
  return columns;

}

const columnsBookingReport = [
  {
    title: 'Источник',
    dataIndex: 'key',
    width: 150,
  },
  Table.EXPAND_COLUMN,
  {
    title: 'Принятые',
    dataIndex: 'totalAccept',
    width: 80,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}</StyledSum> ,
  },
  {
    title: '% (принятые)',
    dataIndex: 'dAccept',
    width: 80,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(Number(value)): 0} %</StyledSum> ,
  },
  {
    title: 'Отклоненные',
    dataIndex: 'totalDecline',
    width: 80,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}</StyledSum> ,
  },
  {
    title: '% (отклоненные)',
    dataIndex: 'dDecline',
    width: 80,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(Number(value)): 0} %</StyledSum> ,
  },
  {
    title: 'В ожидании',
    dataIndex: 'totalRequest',
    width: 80,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}</StyledSum> ,
  },
  {
    title: '% (в ожидании)',
    dataIndex: 'dRequest',
    width: 80,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(Number(value)): 0} %</StyledSum> ,
  },
  {
    title: 'Всего',
    dataIndex: 'totalAmount',
    width: 80,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}</StyledSum> ,
  },
  
    
]

const columnsCallsReport = [
  {
    title: 'Отделы',
    dataIndex: 'key',
    width: 150,
  },
  Table.EXPAND_COLUMN,
  {
    title: 'Успешные',
    dataIndex: 'totalSuccess',
    width: 80,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}</StyledSum> ,
  },
  {
    title: '% (успешные)',
    dataIndex: 'dSuccess',
    width: 80,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(Number(value)): 0} %</StyledSum> ,
  },
  {
    title: 'Пропущенные',
    dataIndex: 'totalMissed',
    width: 80,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}</StyledSum> ,
  },
  {
    title: '% (пропущенные)',
    dataIndex: 'dMissed',
    width: 80,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(Number(value)): 0} %</StyledSum> ,
  },
  {
    title: 'Автоответчик',
    dataIndex: 'totalAutoanswer',
    width: 80,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}</StyledSum> ,
  },
  {
    title: '% (автоответчик)',
    dataIndex: 'dAutoanswer',
    width: 80,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(Number(value)): 0} %</StyledSum> ,
  },
  {
    title: 'Всего',
    dataIndex: 'totalAmount',
    width: 80,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU').format(Number(value)): 0}</StyledSum> ,
  },
  
    
]

const columnsPromoReportTable = [
  {
    title: 'Имя',
    dataIndex: 'name',
    width: 150,
  },
  {
    title: 'ID',
    dataIndex: 'id',
    width: 30,
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    width: 30,
  },
  {
    title: 'Продано столов',
    dataIndex: 'tableSales',
    width: 30,
  },
  {
    title: 'Выручка',
    dataIndex: 'viruchka',
    width: 30,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(value)): 0}</StyledSum> ,
  },
  {
    title: 'Заработок',
    dataIndex: 'zarabotok',
    width: 30,
    render: (value) => <StyledSum>{(value)? new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(value)): 0}</StyledSum> ,
  },
  
    
]

const columnsPromoutersTable = (searchText, getColumnSearchProps, handlerClickId) => {
  return [
    {
      title: '№',
      dataIndex: 'id',
      width: 250,
      sorter: (a, b) => a.id - b.id,
      ...getColumnSearchProps('id'),
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: (id) => <a onClick={()=>handlerClickId(id)}>{searchText ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={id.toString()}
        />
      ) : (
        id
      )}</a>
    },
    {
      title: 'ДАТА СОЗДАНИЯ',
      dataIndex: 'createdAt',
      width: 100,
      sorter: (a, b) => a.createdAt && b.createdAt ? a.createdAt.localeCompare(b.createdAt) : 0,
      ...getColumnSearchProps('createdAt'),
      render: (value) => dayjs(value).format('DD.MM.YYYY')
    },
    {
      title: 'ТЕЛЕФОН',
      dataIndex: 'phone',
      width: 110,
      sorter: (a, b) => a.phone - b.phone,
      ...getColumnSearchProps('phone'),
    },
    {
      title: 'ФИО',
      dataIndex: 'fio',
      width: 140,
      sorter: (a, b) => a.fio && b.fio ? a.fio.localeCompare(b.fio) : 0,
      ...getColumnSearchProps('fio'),
      render: (value, record) => (<>
        {`${record.lastName} ${record.firstName}`}
      </>)
    },
    
    {
      title: 'ДАТА РОЖ..',
      width: 85,
      dataIndex: 'birthdate',
      sorter: (a, b) => a.birthdate && b.birthdate ? a.birthdate.localeCompare(b.birthdate) : 0,
      ...getColumnSearchProps('birthdate'),
      render: (value) => (value) ? dayjs(value).format('DD.MM.YYYY') : ''
    },
    {
      title: 'ПОЛ',
      dataIndex: 'sex',
      // align: 'center',
      width: 24,
      filters: [
        {
          text: 'муж',
          value: 'male',
        },
        {
          text: 'жен',
          value: 'female',
        },
      ],
      onFilter: (value, record) => record.sex === value,
      render: (sex => {
        return (sex === 'male')
          ? <ManOutlined style={{ color: "#52c41a" }} />
          : <WomanOutlined style={{ color: "#eb2f96"}}/>
      })
    },
    {
      title: 'БОНУСЫ',
      dataIndex: 'bonus',
      width: 90,
      // align: 'right',
      // className: 'headerCenter',
      render: (o: any[]) => (
        <>
          {(o)
            ? <Sspan>{new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(o))}</Sspan>
            : <Nspan>{new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(0))}</Nspan>}
        </>
      )
    },
    {
      title: 'КОМИССИЯ',
      dataIndex: 'commi',
      width: 90,
      // align: 'right',
      // className: 'headerCenter',
      render: (o: any[]) => (
        <>
          {(o)
            ? <Sspan>{new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(o))} %</Sspan>
            : <Nspan>{new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(0))} %</Nspan>}
        </>
      )
    },
    {
      title: 'СТАТУС',
      dataIndex: 'shopStatus',
      align: 'center',
      width: 70,
      filters: [
        { text: 'Активен', value: true },
        { text: 'Неактивен', value: false },
      ],
      onFilter: (value, record) => record.isActive === value,
      render: (act, record) => {
        return (act === 'accepted')
          ? <AcceptedLabel>Подтвержден</AcceptedLabel>
          : (act === 'rejected')
            ? <AcceptedLabel><span>Заблокирован</span></AcceptedLabel>
            : <StatusSelector record={record} />
      }
    },
    
]};
  
export {
  columnsBookingReport,
  columnsCallsReport,
  columnsPromoReportTable,
  columnsPromoutersTable,
  columnsMissedCallsReport,
}
  
const AcceptedLabel = styled.div`
  /* Frame 427322058 */

  box-sizing: border-box;

  width: 133px;
  height: 38px;
  /* #8F8F8F 30% */
  border: 1px solid rgba(143, 143, 143, 0.3);
  border-radius: 5px;
  /* Подтвержден */

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  /* Текст/#333333 текст|кнопки */
  color: #333333;

  & span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    color: #DD1A6A;
  }
`

const StyledSum = styled.div`
  text-align: right;
  padding-right: 20%;
`

const Sspan = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  text-align: right;

  /* Текст/#333333 текст|кнопки */
  color: #333333;
`

const Nspan = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  text-align: right;

  /* Текст/#8F8F8F текст */
  color: #8F8F8F;


`

