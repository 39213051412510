import { useMemo, useState, useEffect} from 'react';
import PienutChart from './PienutChart';
import styled from 'styled-components';
import { useApplication } from '../../../context/ApplicationContext';

function Plag5() {
  const { dateRange, setDateRange } = useApplication()

  const [data, setData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)

  const urlForRemains = useMemo(() => {
    const from = (dateRange && dateRange.from) ? dateRange.from : '2024-01-01'
    const to = (dateRange && dateRange.to) ? dateRange.to : '2024-11-01'
    const url = `${process.env.REACT_APP_API_URL}/shops/1/reports/cctv/totals/sex?from=${from}&to=${to}`
    return url
  }, [dateRange])

  useEffect(() => {
    Promise.all([
        fetch(urlForRemains, {
          // credentials: 'include',
          headers: {
            'accept': 'application/json',
            'Authorization': `${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json',

          }
        })
          .then(res => res.json())
          .catch(() => []),
    ])
      .then(data => {
        if (data && data[0] && data[0].female && data[0].male) {
          const female = data && Number(data[0].female)
          const male = data && Number(data[0].male)
          const human = female + male
          const temp = [
            { text: 'Общее число', color: 'gray', value: human, add: (human) ? Math.round(human / human * 100) : 100 },
            { text: 'Мужчины', color: '#2B1ECA', value: male, add: (human) ? Math.round(male / human * 100) : 100 },
            { text: 'Жeнщины', color: '#DD1A6A', value: female, add: (human) ? Math.round(female / human * 100) : 100 },
          ]
          setData(temp)
          setLoading(false)

        }
      })
  }, [urlForRemains])

  return (
    <Plag5Wrap>
      <Header>Видеоаналитика</Header>
      {data && data[0] && (<PlaginsWrap>
        <RadialWrap>
          <PienutChart value={data[0].value} /> {/* Пример с 75% заполнением */}
        </RadialWrap>
        <StyledInfoWrap>
          {data.map(i => (
            <StyledInfoItem>
              <StyledIndicator $color={i.color}></StyledIndicator>
              <StyledTextWrap>
                <StyledHeader>{i.text}</StyledHeader>
                <StyledText>{i.value}<span>{i.add}%</span></StyledText>
              </StyledTextWrap>
            </StyledInfoItem>
          ))}
        </StyledInfoWrap>
      </PlaginsWrap>)}

    </Plag5Wrap>
  )
}

export default Plag5;

const Plag5Wrap = styled.div`
`
const Header = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  /* identical to box height, or 112% */
  display: flex;
  align-items: center;

  /* Текст/#333333 текст|кнопки */
  color: #333333;


`

const StyledInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const StyledInfoItem = styled.div`
  display: flex;
  flex-direction: raw;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  width: 170px;
`
const StyledIndicator = styled.div<{
  $color: string;
}>`
  background: ${({ $color }) => ($color) ? $color : '#ffffff'};
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 17px;
`
const StyledTextWrap = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledHeader = styled.div``
const StyledText = styled.div`
  width: 28px;
  height: 23px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  /* identical to box height, or 164% */
  display: flex;
  align-items: center;
  color: #333333;

  & span {
    margin-left: 17px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 15px;
    color: #CA281E;
  }
`

const PlaginsWrap = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: raw;
  flex-wrap: wrap;
  gap: 18px;
  padding: 10px;
`
const RadialWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 214px;
  height: 214px;
  align-items: center;
  text-align: center;
  
  & span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 28px;
    color: #333333;

  }
`