import { CSSProperties, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components'
import moment from 'moment';
import dayjs from 'dayjs';
import { PuffLoader } from 'react-spinners';
import useFetchData from '../../hooks/useFetchData';
import { DatePicker, Select } from 'antd'
import { useApplication } from '../../context/ApplicationContext';
import { calcTableDataWithNestedByGroupTarget } from './components/constsTableReportPage';

import { Icons } from '../../assets';

import { LineChart } from '../rkeeper/components/LineChart';
import CallsReport from './components/CallsReportTable';

const { RangePicker } = DatePicker;

moment().format();

interface ICall {
  target: string
  date: string
  success: number
  missed: number
  autoanswer: number
}

const CallsReportPage = () => {
  const { dateRange, setDateRange } = useApplication()
  const [selectValue, setSelectValue] = useState(null);
  const [dataChart, setDataChart] = useState<any | null>(null);
  const [dataTable, setDataTable] = useState<any | null>(null);
  const [formatedAtsData, setFormatedAtsData] = useState<ICall[]>([]);
  const [formatedTargetsArray, setFormatedTargetsArray] = useState<string[]>([])
  const [formatedDatesArray, setFormatedDatesArray] = useState<string[]>([])
  
  useEffect(() => {
    if (formatedAtsData.length && formatedDatesArray.length) {
      const filteredAtsData = (selectValue)
        ? formatedAtsData.filter((o: ICall) => o.target === selectValue)
        : formatedAtsData
      const atsDataGroupedByDate = getAtsDataGroupedByDate(filteredAtsData)
      
      const dataFilteredForChart = {
        key: selectValue,
        range: formatedDatesArray,
        values: [
          { key: 'Успешные', range: formatedDatesArray, values: atsDataGroupedByDate.map((o: any) => o.success) },
          { key: 'Прорущенные', range: formatedDatesArray, values: atsDataGroupedByDate.map((o: any) => o.missed) },
          { key: 'Автоответчик', range: formatedDatesArray, values: atsDataGroupedByDate.map((o: any) => o.autoanswer) },

        ]
      }
      
      setDataChart(dataFilteredForChart)

    }
  }, [formatedAtsData, selectValue, formatedDatesArray])

  const from = useMemo(() => {
    return dateRange && dateRange.from ? dateRange.from : moment().add(-1, 'month').utc().valueOf()
  } , [dateRange])
  const to = useMemo(() => dateRange && dateRange.to ? dateRange.to : moment().utc().valueOf(), [dateRange])

  const urlForRemains = useMemo(() => {
    return `${process.env.REACT_APP_API_URL}/shops/1/reports/ats?from=${moment.utc(from).format('YYYY-MM-DD')}&to=${moment.utc(to).format('YYYY-MM-DD')}`
  }, [from, to]) 

  const {
    data: callsData,
  } = useFetchData(urlForRemains,
    [urlForRemains, from, to]);
  
  const urlForAts= useMemo(() => {
    return `${process.env.REACT_APP_API_URL}/shops/1/reports/ats/days?from=${moment.utc(from).format('YYYY-MM-DD')}&to=${moment.utc(to).format('YYYY-MM-DD')}`
  }, [from, to])

  const {
    data: atsData,
    loading,
  } = useFetchData(urlForAts,
    [urlForAts, from, to]);
  
  const getAtsDataGroupedByDate = (arr: ICall[]) => {
    // Группируем по ключам target и date
    const grouped = arr.reduce((acc: any, item: ICall) => {
      const key = `${item.date}`;
      if (!acc[key]) {
      acc[key] = { date: item.date, autoanswer: 0, missed: 0, success: 0 };
      }
      acc[key].autoanswer += item.autoanswer;
      acc[key].missed += item.missed;
      acc[key].success += item.success;
      return acc;
    }, {});

    // Преобразуем объект в массив
    return Object.values(grouped);
  }

  useEffect(() => {
    if (atsData) {
      const datesArr: string[] = atsData.map((item: ICall) => item.date);
      const uniqueSortedDates: string[] = Array.from(new Set(datesArr));
      const targetsArr: string[] = atsData.map((item: ICall) => item.target);
      const uniqueSortedTargets: string[] = Array.from(new Set(targetsArr));
      const result: ICall[] = [];
      uniqueSortedDates.forEach(date => {
        uniqueSortedTargets.forEach(target => {
            // Проверяем, существует ли объект с данной комбинацией date и target
            const existingItem = atsData.find(item => item.date === date && item.target === target);
          if (existingItem) {
            result.push(existingItem);
          } else {
            // Если объект не найден, добавляем объект с нулевыми значениями
            result.push({ date, target, autoanswer: 0, missed: 0, success: 0 });
          }
        });
      });
      const newData = calcTableDataWithNestedByGroupTarget(atsData)
      
      setDataTable(newData)
      setFormatedDatesArray(uniqueSortedDates)
      setFormatedTargetsArray(uniqueSortedTargets)
      setFormatedAtsData(result)
    }
  }, [atsData])

  // useEffect(() => {
  //   if (callsData) {
  //     console.log('callsData', callsData);
      
  //     const dataFind = callsData.totals
  //     let formatedData: any[] = []
  //     for (const key in dataFind) {
  //       if (Object.prototype.hasOwnProperty.call(dataFind, key)) {
  //         const el = dataFind[key];
  //         const success = +el.success || 0
  //         const missed = +el.missed || 0
  //         const autoanswer = +el.autoanswer || 0
          
  //         formatedData.push({
  //           buro: key,
  //           success: success,
  //           missed: missed,
  //           autoanswer: autoanswer,
  //           total: success + missed + autoanswer,
  //           dSuccess: Math.round(success / (success + missed + autoanswer) * 1000) / 10,
  //           dMissed: Math.round(missed / (success + missed + autoanswer) * 1000) / 10,
  //           dAutoanswer: Math.round(autoanswer / (success + missed + autoanswer) * 1000) / 10,
  //         })
  //       }
  //     }
  //     console.log('formatedData', formatedData);
      
  //     setDataTable(formatedData)

  //   }
  // },  [callsData])

  const cssOverride: CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
  }

  const onDateChange = (date: any, dateString: [string, string]) => {
    
    if (date) {
      setDateRange({
        from: dayjs(dateString[0]).add(1, 'day').valueOf(),
        to: dayjs(dateString[1]).add(1, 'day').valueOf(),
      })
      
      
    } else {
      // setHistoryDateRange([])
    }
  }

  const handleChange = (value: any) => {
    setSelectValue(value)
  };

  return (
    <Wrapper>
      <Header>
        <StyledHeadTitle>Отчет по звонкам</StyledHeadTitle>
        <StyledCaledarWrapper>
          <StyledCalendarMemo>
            <Icons.IconSlideTrendFill />
            Сравнение периодов
          </StyledCalendarMemo>
          <StyledRangePicker
            suffixIcon={<Icons.IconMainCalendar />}
            value={[dayjs(from), dayjs(to)]}
            format={['YYYY-MM-DD', 'YYYY-MM-DD']}
            onChange={onDateChange}
          />
        </StyledCaledarWrapper>
      </Header>
      {loading ? (
        <PuffLoader color="#E65E20" cssOverride={cssOverride} />
      ) : (
        <div>
          {dataChart && (<ChartsWrapper>
            <StyledSelect 
              value={selectValue}
              onChange={handleChange} 
                options={[
                  {value: null, label: 'Все'},
                  ...formatedTargetsArray.map(i => ({
                value: i,
                label: i,
              }))]}
            />
        
            <LineChart dataSource={dataChart} />
          </ChartsWrapper>)}
          {dataTable && <CallsReport
            dataValues={dataTable}
            loading={loading}
          />}
          
        </div>
      )}
    </Wrapper>)
}

export default CallsReportPage;

const StyledSelect = styled(Select)`
  /* Frame 148 */
  position: absolute;
  box-sizing: border-box;
  margin-top: -10px;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 5px 10px; */
  gap: 10px;

  width: 175px;
  height: 38px;
`

const Wrapper = styled.div`
  min-width: 100%;
  height: 80%h;
  background-color: #F7F7F7;
  padding: 16px 29px 16px 0px;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

const StyledRangePicker = styled(RangePicker)`
  width: 250px;
  color: #ffffff;
  
`

const StyledHeadTitle = styled.label`
  font-family: 'Inter';
  font-size: 25px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #333333;
`

const StyledCaledarWrapper = styled.div`
  display: flex;
  gap: 10px;
  color: #ffffff;
  
  & svg {
    width: 20px;
    height: 20px;
  }
  .ant-picker {
    background-color: #A7388E;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
  }

  .ant-picker .ant-picker-input,
  .ant-picker .ant-picker-range,
  .ant-picker .ant-picker-input-active .ant-picker-input-placeholder {
    display: flex;
    flex-direction: row-reverse;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #ffffff;

  }

  .ant-picker .ant-picker-input .ant-picker-input-active {
    color: #ffffff !important;
  }

  .ant-picker .ant-picker-input .ant-picker-input-placeholder {
    color: #ffffff !important;
  }

  .ant-picker .ant-picker-input span.ant-picker-suffix,
  .ant-picker.ant-picker-range span.ant-picker-suffix {
    order: -1;
    margin-left: 0;
    margin-right: 10px; /* Для отступа иконки от текста */
    color: #ffffff;
  }

  .ant-picker.ant-picker-range.ant-picker-range-separator {
    color: #ffffff !important;
    /* display: none; */
  }

  .ant-picker-range-separator > span {
    color: #ffffff !important; /* Замените #ваш_цвет на нужный код цвета */
  }
  
  .ant-picker-clear {
    color: #ffffff !important; /* Замените #ваш_цвет на нужный код цвета */
  }
  
  .ant-picker .ant-picker-clear {
    position: absolute;
    right: 217px;
    /* color: #ffffff !important; */
  }


`

const StyledCalendarMemo = styled.div`
  display: flex;
  width: 220px;
  height: 40px;
  gap: 10px;
  background-color: #E9E9E9;
  border-radius: 5px 0px 0px 0px;
  opacity: 0px;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #333333;

`

const ChartsWrapper = styled.div`
  height: 470px;
  width: 100%;
  padding: 30px 30px 70px;
  background-color: #fff;
  border-radius: 5px;
`
