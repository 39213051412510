export const getStartPeriod = (period) => {
    const now = new Date();
  let startDate;
  
  switch (period) {
    case 'day':
      startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      break;
    case 'week':
      startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
      break;
    case 'mounth':
      startDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
      break;
    case 'year':
      startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
      break;
    default:
      throw new Error('Invalid period');
    }
    return startDate
}

export const filterAndCountByState = (data, period) => {
  
  const startDate = getStartPeriod(period)
  const filteredData = data.filter(item => new Date(item.createdAt) >= startDate);
  const countByState = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 };

  filteredData.forEach(item => {
    countByState[item.state] += 1;
  });

  return countByState;
};
