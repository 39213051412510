import { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import { barsColorArray } from '../../../config/colors';
import { LegendContainer } from './LegendContainer';
import styled from 'styled-components'

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const data = {
  labels: ['27.04', '28.04', '29.04', '30.04', '01.05', '02.05'],
  datasets: [
    {
      label: 'Woman card',
      data: [600, 580, 420, 420, 350, 480],
      backgroundColor: '#DD1A6A',
      borderRadius: 10,
      borderSkipped: false,
      barThickness: 20,
    },
    {
      label: 'Friend card',
      data: [500, 380, 380, 300, 300, 400],
      backgroundColor: '#FBB500',
      borderRadius: 10,
      borderSkipped: false,
      barThickness: 14,
    },
    {
      label: 'Deposit',
      data: [950, 600, 480, 500, 400, 500],
      backgroundColor: '#2B1ECA',
      borderRadius: 10,
      borderSkipped: false,
      barThickness: 20,
    },
  ],
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false, // Отключаем стандартную легенду
    },
  },
  scales: {
    // x: {
    //   type: 'category' as const,
    //   barPercentage: 0.8, // Настройка ширины баров
    //   categoryPercentage: 0.5,// Настройка расстояния между барами
    //   offset: true, // Добавляем отступы между группами столбиков

    // },
    y: {
      ticks: {
        maxTicksLimit: 5, // Установите максимальное количество меток на оси Y
      },
    },
  },
};

const BarChart = () => {
  const [hiddenDatasets, setHiddenDatasets] = useState<number[]>([]);

  if (data && data.datasets) {
    const datas = {
      labels: data.labels,
      datasets: data.datasets.map((item, ind) => ({
        label: item.label,
        data: item.data,
        borderColor: barsColorArray[ind].borderColor,
        backgroundColor: barsColorArray[ind].backgroundColor,
        borderRadius: item.borderRadius,
        barThickness: item.barThickness,
        fill: false,
        spanGaps: false, // Убедитесь, что пропуски не заполняются
        hidden: hiddenDatasets.includes(ind),
      })),
    };

    return <Wrap> {/* Используем Flexbox */}
        <LegendContainer datas={datas} setHiddenDatasets={setHiddenDatasets} hiddenDatasets={hiddenDatasets} />
        <Bar data={datas} options={options} />
      </Wrap>
  } 
  return <></>
};

export default BarChart;

const Wrap = styled.div`
  height: 320px;
`