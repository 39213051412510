import { CSSProperties, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components'
import moment from 'moment';
import dayjs from 'dayjs';

import { PuffLoader } from 'react-spinners';
import { DatePicker, Select } from 'antd'
import type { DatePickerProps } from 'antd';

import useFetchData from '../../hooks/useFetchData';
import history from '../../hystory'
import { LineChart } from '../rkeeper/components/LineChart';
import ZoneAnaliticsTable from './components/ZoneAnaliticsTable';

moment().format();

const maskRange = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]

const optionsSelect = [
  { value: 'all', label: 'Все'},
  { value: 'male', label: 'Мужчины'},
  { value: 'female', label: 'Женщины'},
]
  
const ZoneAnaliticsPage = () => {
  const [selectValue, setSelectValue] = useState<string>('all');
  const [dataChartAll, setDataChartAll] = useState<any[]>([])
  const [chartMemo, setChartMemo] = useState<any>();
  const [datePickerValue, setDatePickerValue] = useState<any>(moment().format('YYYY-MM-DD'))
  const [dataTable, setDataTable] = useState([]);

  const { pathname, search } = window.location
  const searchParams = new URLSearchParams(search);

  const urlForRemains = useMemo(() => {
    const ddate = (datePickerValue) ? datePickerValue : moment().format('YYYY-MM-DD')
    return `${process.env.REACT_APP_API_URL}/shops/1/reports/cctv/totals?date=${ddate}&period=hour`
  }, [datePickerValue]) 
  
  const {
    data: timezoneData,
    loading,
    setParams: setHeaderParams,
  } = useFetchData(urlForRemains,
    [urlForRemains]);
  
  const cssOverride: CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
  }

  const onDateChange: DatePickerProps['onChange'] = (date: any, dateString: any) => {
    
    if (dateString) {
      searchParams.set('date', dateString.toString());
      setDatePickerValue(dateString.toString())
      setHeaderParams([{ url: urlForRemains }])
      history?.replace({
        pathname,
        search: searchParams.toString(),
      })

    } else {

    }
  };

  const disabledDate: DatePickerProps['disabledDate'] = (current) => {
    return current && (current > dayjs().endOf('day'))
  }

  useEffect(() => {
    if (timezoneData) {
      const convertedData = timezoneData.map(i => ({
        hour: moment(i.datetime).format('HH'),
        hourE: moment(i.datetime).add(1, 'hour').format('HH'),
        datetime: i.datetime,
        age: i.age,
        female: i.female,
        male: i.male,
        human: i.male + i.female,
        uhod: 0
      }))
      const summedData = convertedData.reduce((acc, curr) => {
        const existing = acc.find(item => item.hour === curr.hour);
        if (existing) {
          existing.time = `${existing.hour}:00-${existing.hourE}:00`
          existing.female += curr.female;
          existing.male += curr.male;
          existing.human += curr.human;
          existing.uhod += curr.uhod;
        } else {
          acc.push({ ...curr });
        }
        return acc;
      }, []);

      setDataTable(summedData)
    }
    
  }, [timezoneData])

  useEffect(() => {
    if (dataTable) {
      const completeData = maskRange.map(hour => {
        const existing = dataTable.find((item: any) => item.hour === hour.toString().padStart(2, '0'));
        if (existing) {
          return existing;
        } else {
          return {
            hour: hour.toString().padStart(2, '0'),
            female: 0,
            male: 0,
            human: 0,
            uhod: 0,
          };
        }
      });

      setDataChartAll(completeData);
    }
  }, [dataTable])

  useEffect(() => {
    if (selectValue === 'male') {
      const dataChartMale = {
        range: maskRange,
        values: [
          { key: 'Приход', range: maskRange, values: dataChartAll.map(item => item.male), borderColor: 'rgb(43, 30, 202)', backgroundColor: 'rgba(43, 30, 202, 0.5)' },
          { key: 'Уход', range: maskRange, values: dataChartAll.map(item => 0), borderColor: 'rgb(251, 181, 0)', backgroundColor: 'rgba(251, 181, 0, 0.5)' },
        ] 
      }
      setChartMemo(dataChartMale)
    }
    if (selectValue === 'female') {
      const dataChartFemale = {
        range: maskRange,
        values: [
          { key: 'Приход', range: maskRange, values: dataChartAll.map(item => item.female), borderColor: 'rgb(221, 26, 106)', backgroundColor: 'rgba(221, 26, 106, 0.5)' },
          { key: 'Уход', range: maskRange, values: dataChartAll.map(item => 0), borderColor: 'rgb(251, 181, 0)', backgroundColor: 'rgba(251, 181, 0, 0.5)' },
        ] 
      }
      setChartMemo(dataChartFemale)
    }
    if (selectValue === 'all') {
      const dataChartHuman = {
        range: maskRange,
        values: [
          { key: 'Приход', range: maskRange, values: dataChartAll.map(item => item.human), borderColor: 'rgb(46, 194, 16)', backgroundColor: 'rgba(46, 194, 16, 0.5)' },
          { key: 'Уход', range: maskRange, values: dataChartAll.map(item => 0), borderColor: 'rgb(251, 181, 0)', backgroundColor: 'rgba(251, 181, 0, 0.5)' },
        ] 
      }
      setChartMemo(dataChartHuman)
    }
  }, [selectValue, dataChartAll])

  const handleChange = (value: any) => {
    setSelectValue(value)
  };

  return (
      <Wrapper>
        <Header>
        <StyledHeadTitle>Временной анализ</StyledHeadTitle>
        <StyledCaledarWrapper>
          <DatePicker
              onChange={onDateChange}
              value={dayjs(datePickerValue)}
              format={'YYYY-MM-DD'}
              // placeholder={datePickerValue}
              disabledDate={disabledDate}
          />

        </StyledCaledarWrapper>
      </Header>
      {loading ? (
        <PuffLoader color="#E65E20" cssOverride={cssOverride} />
      ) : (
        <div>
            {chartMemo && (<ChartsWrapper>
              <StyledSelect 
                value={selectValue}
                onChange={handleChange} 
                options={optionsSelect}
              />
            <LineChart dataSource={chartMemo} />
          </ChartsWrapper>)}
          <ZoneAnaliticsTable
            dataValues={dataTable}
            loading={loading}
          />
          
        </div>
      )}
    </Wrapper>

   
  )
}

export default ZoneAnaliticsPage;

const StyledSelect = styled(Select)`
  /* Frame 148 */
  display: flex;
  position: relative;
  box-sizing: border-box;

  /* Auto layout */
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 5px 10px; */
  top: 30px;
  left: 12px;
  width: 175px;
  height: 38px;
`

const Wrapper = styled.div`
  min-width: 100%;
  height: 80%h;
  background-color: #F7F7F7;
  padding: 16px 29px 16px 0px;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

const StyledHeadTitle = styled.label`
  font-family: 'Inter';
  font-size: 25px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #333333;
`

const StyledCaledarWrapper = styled.div`
  display: flex;
  gap: 10px;
  color: #ffffff;
  
  & svg {
    width: 20px;
    height: 20px;
  }
  .ant-picker {
    background-color: #A7388E;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
  }

  .ant-picker .ant-picker-input,
  .ant-picker .ant-picker-range,
  .ant-picker .ant-picker-input-active .ant-picker-input-placeholder {
    display: flex;
    flex-direction: row-reverse;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #ffffff;

  }

  .ant-picker .ant-picker-input .ant-picker-input-active {
    color: #ffffff !important;
  }

  .ant-picker .ant-picker-input .ant-picker-input-placeholder {
    color: #ffffff !important;
  }

  .ant-picker .ant-picker-input span.ant-picker-suffix,
  .ant-picker.ant-picker-range span.ant-picker-suffix {
    order: -1;
    margin-left: 0;
    margin-right: 10px; /* Для отступа иконки от текста */
    color: #ffffff;
  }

  .ant-picker.ant-picker-range.ant-picker-range-separator {
    color: #ffffff !important;
    /* display: none; */
  }

  .ant-picker-range-separator > span {
    color: #ffffff !important; /* Замените #ваш_цвет на нужный код цвета */
  }
  
  .ant-picker-clear {
    color: #ffffff !important; /* Замените #ваш_цвет на нужный код цвета */
  }
  
  .ant-picker .ant-picker-clear {
    position: absolute;
    right: 217px;
    /* color: #ffffff !important; */
  }
`

const ChartsWrapper = styled.div`
  height: 470px;
  width: 100%;
  margin-bottom: 100px;
  background-color: #fff;
  border-radius: 5px;
`
