import { CSSProperties, useMemo, useState } from 'react';
import styled from 'styled-components'
import moment from 'moment';
import dayjs from 'dayjs';
import { PuffLoader } from 'react-spinners';
import useFetchData from '../../hooks/useFetchData';
import { DatePicker, Select, Radio } from 'antd'
import { useApplication } from '../../context/ApplicationContext';
import HorizontalBarChart from './components/HorizontalBarChart';
import { Icons } from '../../assets';
import RadialBarChart from './components/RadialBar';
import { LineChart } from '../rkeeper/components/LineChart';
import LTVTable from './components/LTVTable';
import FrameSex from './components/FrameSex';
import FrameAge from './components/FrameAge';

const { RangePicker } = DatePicker;

moment().format();

const dataChart = {
  range: ['2024-04-26', '2024-04-27', '2024-04-28', '2024-04-29', '2024-04-30', '2024-05-01', '2024-05-02', '2024-05-02'],
  values: [
    {
      key: 'женщины',
      range: ['2024-04-26', '2024-04-27', '2024-04-28', '2024-04-29', '2024-04-30', '2024-05-01', '2024-05-02', '2024-05-02'],
      values: [7,12,11,10,17,19,11,12]
    },
    {
      key: 'мужчины',
      range: ['2024-04-26', '2024-04-27', '2024-04-28', '2024-04-29', '2024-04-30', '2024-05-01', '2024-05-02', '2024-05-02'],
      values: [2,3,3,2,1,1,1,2,3]
    },
    
  ]
}

interface ILTV {
  age: string,
  man: number,
  ltv_man?: number,
  woman: number,
  ltv_woman?: number,
  all?: number,
  ltv_all?: number,
}
const dataTable: ILTV[] = [
  {age: 'до 25', man: 23, ltv_man: 14, woman: 32, ltv_woman: 14, all: 55, ltv_all: 14},
  {age: '25-35', man: 44, ltv_man: 14, woman: 56, ltv_woman: 14, all: 100, ltv_all: 14},
  {age: '35-45', man: 44, ltv_man: 14, woman: 56, ltv_woman: 14, all: 100, ltv_all: 14},
  {age: 'более 45', man: 44, ltv_man: 14, woman: 56, ltv_woman: 14, all: 100, ltv_all: 14},
]

const LTVPage = () => {
  const { dateRange, setDateRange } = useApplication()
  const [ selectValue, setSelectValue ] = useState<string>('age');
  const [size, setSize] = useState<string>('1M'); // default is 'middle'
  const from = useMemo(() => {
    return dateRange && dateRange.from ? dateRange.from : moment().add(-1, 'month').utc().valueOf()
  } , [dateRange])
  const to = useMemo(() => dateRange && dateRange.to ? dateRange.to : moment().utc().valueOf(), [dateRange])

  const urlForRemains = useMemo(() => {
    return `${process.env.REACT_APP_API_URL}/shops/1/reports/revenue/station/days?from=${moment.utc(from).format('YYYY-MM-DD')}&to=${moment.utc(to).format('YYYY-MM-DD')}`
  
  }, [from, to]) 
  // const urlForRemainsGrouped = useMemo(() => `${process.env.REACT_APP_API_URL}/shops/1/reports/revenue/station?from=${moment.utc(from).format('YYYY-MM-DD')}&to=${moment.utc(to).format('YYYY-MM-DD')}`, [from, to])
  
  const {
    data: viruchkaData,
    loading,
    setParams: setHeaderParams,
  } = useFetchData(urlForRemains,
    [urlForRemains, from, to]);
  
  const cssOverride: CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
  }

  const onDateChange = (date: any, dateString: [string, string]) => {
    
    if (date) {
      setDateRange({
        from: dayjs(dateString[0]).add(1, 'day').valueOf(),
        to: dayjs(dateString[1]).add(1, 'day').valueOf(),
      })
      
      
    } else {
      // setHistoryDateRange([])
    }
  }

  const dataLegendSex = [
    {backgroundColor: '#2B1ECA', label: 'Мужчины'},
    {backgroundColor: '#DD1A6A', label: 'Женщины'},
    
  ]
  const dataLegendAge = [
    {backgroundColor: '#FBB500', label: 'до 25'},
    {backgroundColor: '#A7388E', label: '25-35'},
    {backgroundColor: '#6B00F3', label: '35-45'},
    {backgroundColor: '#BF6DFF', label: 'более 45'},
    
  ]
  const optionsSelect = [
    { value: 'sex', label: 'Пол'},
    { value: 'age', label: 'Возраст'},
  ]

  const handleChange = (value: any) => {
    setSelectValue(value)
  };

  return (
      <Wrapper>
        <Header>
        <StyledHeadTitle>LTV</StyledHeadTitle>
        {/* <StyledCaledarWrapper>
          <StyledCalendarMemo>
            <Icons.IconSlideTrendFill />
            Сравнение периодов
          </StyledCalendarMemo>
          <StyledRangePicker
            suffixIcon={<Icons.IconMainCalendar />}
            value={[dayjs(from), dayjs(to)]}
            format={['YYYY-MM-DD', 'YYYY-MM-DD']}
            onChange={onDateChange}
          />
        </StyledCaledarWrapper> */}
      </Header>
      {loading ? (
        <PuffLoader color="#E65E20" cssOverride={cssOverride} />
      ) : (
        <div>
          <ChartsWrapper>
            <ChartsHeaderWrapper>
              <StyledSelect 
                value={selectValue}
                onChange={handleChange} 
                options={optionsSelect}
              />
              <Radio.Group value={size} onChange={(e) => setSize(e.target.value)}>
                <Radio.Button value="1M">1 месяц</Radio.Button>
                <Radio.Button value="3M">3 месяца</Radio.Button>
                <Radio.Button value="6M">6 месяцев</Radio.Button>
                <Radio.Button value="1Y">1 год</Radio.Button>
              </Radio.Group>
                {selectValue === 'sex' ? (
                  <LegendWrap>
                    {dataLegendSex.map((dataset, i) => (
                      <LegendItem key={i}>
                        <LegendColorBox color={dataset.backgroundColor}>
                          <CheckIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 11" fill="none" stroke="white" >
                            <path d="M1.5 3.68778L5.82143 8.61538L12.5 1" stroke-width="2"></path>
                          </CheckIcon>
                      
                        </LegendColorBox>
                        <LegendLabel>{dataset.label}</LegendLabel>
                      </LegendItem>
                    ))}
                  </LegendWrap>)
                : (
                  <LegendWrap>
                    {dataLegendAge.map((dataset, i) => (
                      <LegendItem key={i}>
                        <LegendColorBox color={dataset.backgroundColor}>
                          <CheckIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 11" fill="none" stroke="white" >
                            <path d="M1.5 3.68778L5.82143 8.61538L12.5 1" strokeWidth="2"></path>
                          </CheckIcon>
                      
                        </LegendColorBox>
                        <LegendLabel>{dataset.label}</LegendLabel>
                      </LegendItem>
                    ))}
                  </LegendWrap>)}
              <Radio.Group value={size} onChange={(e) => setSize(e.target.value)}>
                <Radio.Button value="1M">Схема</Radio.Button>
                <Radio.Button value="3M">График</Radio.Button>
              </Radio.Group>
            </ChartsHeaderWrapper>
            {dataChart && selectValue === 'sex' && (<FrameSex />)}
            {dataChart && selectValue === 'age' && (<FrameAge />)}

          </ChartsWrapper>
          <LTVTable
            dataValues={dataTable}
            loading={loading}
          />
          
        </div>
      )}
    </Wrapper>

   
  )
}

export default LTVPage;

const ChartsHeaderWrapper = styled.div`
  display: flex;
  flex-direction: raw;
  justify-content: space-between;
`

const LegendWrap = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const LegendItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
`;

const LegendColorBox = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-color: ${props => props.color};
  border-radius: 2px;
`;

const LegendLabel = styled.span`
  margin-right: 10px;
  /* Woman card */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #333333;
`;

const CheckIcon = styled.svg`
  width: 16px;
  height: 16px;
  /* fill: white; Цвет галочки */
`;

const Wrapper = styled.div`
  min-width: 100%;
  height: 80%h;
  background-color: #F7F7F7;
  padding: 16px 29px 16px 0px;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

const StyledRangePicker = styled(RangePicker)`
  width: 250px;
  color: #ffffff;
  
`

const StyledHeadTitle = styled.label`
  font-family: 'Inter';
  font-size: 25px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #333333;
`

const StyledSelect = styled(Select)`
  /* Frame 148 */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 5px 10px; */
  gap: 10px;

  width: 175px;
  height: 38px;
`

const StyledCaledarWrapper = styled.div`
  display: flex;
  gap: 10px;
  color: #ffffff;
  
  & svg {
    width: 20px;
    height: 20px;
  }
  .ant-picker {
    background-color: #A7388E;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
  }

  .ant-picker .ant-picker-input,
  .ant-picker .ant-picker-range,
  .ant-picker .ant-picker-input-active .ant-picker-input-placeholder {
    display: flex;
    flex-direction: row-reverse;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #ffffff;

  }

  .ant-picker .ant-picker-input .ant-picker-input-active {
    color: #ffffff !important;
  }

  .ant-picker .ant-picker-input .ant-picker-input-placeholder {
    color: #ffffff !important;
  }

  .ant-picker .ant-picker-input span.ant-picker-suffix,
  .ant-picker.ant-picker-range span.ant-picker-suffix {
    order: -1;
    margin-left: 0;
    margin-right: 10px; /* Для отступа иконки от текста */
    color: #ffffff;
  }

  .ant-picker.ant-picker-range.ant-picker-range-separator {
    color: #ffffff !important;
    /* display: none; */
  }

  .ant-picker-range-separator > span {
    color: #ffffff !important; /* Замените #ваш_цвет на нужный код цвета */
  }
  
  .ant-picker-clear {
    color: #ffffff !important; /* Замените #ваш_цвет на нужный код цвета */
  }
  
  .ant-picker .ant-picker-clear {
    position: absolute;
    right: 217px;
    /* color: #ffffff !important; */
  }


`

const StyledCalendarMemo = styled.div`
  display: flex;
  width: 220px;
  height: 40px;
  gap: 10px;
  background-color: #E9E9E9;
  border-radius: 5px 0px 0px 0px;
  opacity: 0px;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #333333;

`

const ChartsWrapper = styled.div`
  height: 470px;
  width: 100%;
  padding: 30px;
  background-color: #fff;
  border-radius: 5px;
`
