import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import moment from 'moment';
import ChangeBonusForm from './ChangeBonusForm';
import ChangeBalanceForm from './ChangeBalanceForm';

interface PropsClientModal {
  selectedClient: any
  offset?: any;
  keys?: number;
  setOpeningSettingsPopup: any;
  setLoad: any;
}

moment().format();


const ContentModalCards: React.FC<PropsClientModal> = ({selectedClient, keys, setOpeningSettingsPopup, setLoad}) => {
  // const [selectedClient, setselectedClient] = useState(selectedClient)
  const [loading, setLoading] = useState(true);
  const [dataHistoryBayes, setDataHistoryBayes] = useState<any[]>([])
  const [showFormBalance, setShowFormBalance] = useState(false);
  const [cardFormBalance, setCardFormBalance] = useState(undefined)
  const [showFormBonus, setShowFormBonus] = useState(false);
  const [cardFormBonus, setCardFormBonus] = useState('');
  
  const fetchData = () => {
    const clientsUrl = `${process.env.REACT_APP_API_URL}/shops/1/loyalty/clients/${selectedClient.id}/cards`
      Promise.all([
        fetch(clientsUrl, {
          // credentials: 'include',
          headers: {
            'accept': 'application/json',
            'Authorization': `${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json',
          }
        })
          .then(res => res.json())
          .catch(() => []),
      ])
        .then(([data]) => {
          if (data && Array.isArray(data) && data.length > 0) {
            setDataHistoryBayes(data) 
          } else setDataHistoryBayes([])

        })
  }
  useEffect(() => {
    if (loading) {
      fetchData();
      setLoading(false);
    }
  }, [loading])

  useEffect(() => {
    if (!showFormBonus || !showFormBalance) fetchData()
  }, [showFormBonus, showFormBalance])

  const toggleFormBalance = (id) => {
    setShowFormBonus(false)
    setShowFormBalance(!showFormBalance)
    setCardFormBalance(id)
  }

  const toggleFormBonus = (id) => {
    setShowFormBalance(false)
    setShowFormBonus(!showFormBonus)
    setCardFormBonus(id)
  }

  return (
    <Wrapper>
      <Hr />
      <StyledTableWrapper>
        <StyledTableHeader>
          <HeaderTitle style={{width: '130px'}}>Тип карты</HeaderTitle>
          <HeaderTitle style={{width: '130px'}}>Номер карты</HeaderTitle>
          <HeaderTitle>Депозитный баланс</HeaderTitle>
          <HeaderTitle>Бонусный баланс</HeaderTitle>
        </StyledTableHeader>
        <Br />
        <StyledTableBody>
          {dataHistoryBayes.map((item, ind) => (
            <React.Fragment key={`fragment1-${ind}`}>
              <InfoWrap key={`infowrap1-${ind}`}>
                <InfoCardName key={`infoCardName1-${ind}`}>{item?.template?.displayName}</InfoCardName>
                <InfoCardNum key={`infocard1-${ind}`}>{item.id}</InfoCardNum>
                <InfoSumm
                  key={`infosumbalance-${ind}`}
                  // onClick={()=>toggleFormBalance(item.id)}
                  >
                    {(item.balance) ? new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(item.balance)) : '0,00'}
                </InfoSumm>
                
                {(showFormBonus && item.id === cardFormBonus) && (
                  <ChangeBonusForm
                    card={item}
                    setShowFormBonus={setShowFormBonus}
                    setLoading={setLoading}
                  />
                )}
                
                  <InfoSumm
                    key={`infosumbonus-${ind}`}
                  onClick={() => toggleFormBonus(item.id)}
                    style={{cursor: 'pointer'}}
                  >
                  {(item.bonus) ? new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(item.bonus)) : '0,00'}
                </InfoSumm>
                {(showFormBalance && item.id === cardFormBalance) && (
                  <ChangeBalanceForm
                    card={item}
                    setShowFormBalance={setShowFormBalance}
                    setLoading={setLoading}
                    setLoad={setLoad}
                  />
                )}
                
              </InfoWrap>
              <Br />
            </React.Fragment>
          ))}
        </StyledTableBody>
      </StyledTableWrapper>
      <StyledFooter>
        <StyledButtonClose onClick={() => setOpeningSettingsPopup(false)}>Закрыть</StyledButtonClose>
        <StyledButtonSave onClick={() => setOpeningSettingsPopup(false)}><span>Сохранить</span></StyledButtonSave>
      </StyledFooter>
    </Wrapper>
   
  )
}

export default ContentModalCards;

const StyledTableWrapper = styled.div`
  height: 643px;
`

const StyledTableHeader = styled.div`
  display: flex;
  flex-direction: row nowrap;
  width: 100%;
  height: 46px;
  gap: 5px;
  padding: 10px;
`

const HeaderTitle = styled.div`
    /* Организация */

  width: 150px;
  height: 23px;
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  /* identical to box height, or 164% */
  display: flex;
  align-items: center;

  /* Текст/#333333 текст|кнопки */
  color: #333333;
`

const StyledTableBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  max-height: 470px;
  overflow-y: auto;
  overflow-x: hidden;
`

const InfoCardNum = styled.div`
  /* Микс Afterparty (MIDSRV) */

  width: 130px;
  height: 30px;
  left: 13.25px;
  top: 7px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  /* or 107% */
  display: flex;
  align-items: center;

  /* Текст/#333333 текст|кнопки */
  color: #333333;


`

const InfoCardName = styled.div`
    /* Ручное списание бонусов */

  width: 130px;
  height: 45px;
  left: 130.5px;
  top: -1px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  /* or 107% */
  display: flex;
  align-items: center;

  /* Текст/#A7388E текст|кнопки */
  color: #A7388E;


`

const InfoSumm = styled.div`
  /* 0,00 */

  width: 150px;
  height: 15px;
  left: 238.25px;
  top: 14px;
  padding-left: 0px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  /* identical to box height, or 107% */
  display: flex;
  align-items: center;

  /* Текст/#8F8F8F текст */
  color: #8F8F8F;


`

const InfoWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`

const StyledButtonClose = styled.div`
  /* Закрыть */

  width: 59px;
  height: 28px;

  /* Текст */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  display: flex;
  align-items: center;
  text-align: center;

  /* Текст/#8F8F8F текст */
  color: #8F8F8F;
  cursor: pointer;

`

const StyledButtonSave = styled.div`
    /* Frame 37 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 58px;
  gap: 10px;
  cursor: pointer;
  width: 195px;
  height: 40px;

  /* Текст/#A7388E текст|кнопки */
  background: #A7388E;
  border-radius: 5px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;

  & span {
        /* Сохранить */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    align-items: center;
    text-align: center;

    /* Текст/#FFFFFF текст|иконки|кнопки */
    color: #FFFFFF;
  }
`

const StyledFooter = styled.div`
  /* Frame 40 */
  display: flex;
  gap: 34px;
  flex-direction: row;
  justify-content: flex-end;
  height: 40px;
  margin-top: 11px;
  align-items: center;
  position: absolute;
  bottom: 10px;
  right: 10px;
`

const Hr = styled.hr`
  /* Разделительная */
  width: 675px;
  height: 0px;
  margin-bottom: 15px;
  border: 3px solid rgba(143, 143, 143, 0.3);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);

`;

const Br = styled.hr`
  /* Vector 11 */
  margin: 5px 0px;
  width: 675px;
  height: 0px;

  border: 1px solid rgba(143, 143, 143, 0.3);
  
`

const Wrapper = styled.div`
  /* Frame 85 */
  display: flex;
  flex-direction: column;
  width: 675px;
  height: 524px;
  z-index: 1205;
  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
  
`
