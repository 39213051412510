import { useState, useEffect, useMemo } from 'react';
import styled from "styled-components";
import { Select } from "antd";

import PeriodSelector from './PeriodSelector';

interface IISelector {
  key: string
  value: string
  label: string
}
const selectOptions: IISelector[] = [
  { key: 'day', value: 'day', label: 'За день' },
  { key: 'week', value: 'week', label: 'За неделю' },
  { key: 'mounth', value: 'mounth', label: 'За месяц' },
  { key: 'year', value: 'year', label: 'За год' },
]

const HeaderInfo = () => {
  const [selectValue, setSelectValue] = useState<IISelector>(selectOptions[0])
  const [data, setData] = useState<any>([])

  const urlForRemains = useMemo(() => `${process.env.REACT_APP_API_URL}/shops/1/loyalty/clients/topStats?period=${selectValue.value}`, [selectValue])

  useEffect(() => {
     Promise.all([
      fetch(urlForRemains, {
        // credentials: 'include',
        headers: {
          'accept': 'application/json',
          'Authorization': `${localStorage.getItem('accessToken')}`,
          'Content-Type': 'application/json',
        }
      })
        .then(res => res.json())
        .catch(() => []),
    ])
      .then(([dataFetch]) => {
        if (dataFetch) {
          setData(dataFetch)
        } else setData([])
      })
  }, [urlForRemains])

  return (<HeaderInfoWrap>
        <CardInfo>
          <CardTextArea>
            <PeriodSelector options={selectOptions} setSelectValue={setSelectValue} value={selectValue} />
            <CardText>период статистики</CardText>
          </CardTextArea>
          <CardDesc></CardDesc>
        </CardInfo>
        <CardInfo>
          <CardTextArea>
        <CardTitle>{data.registrations}</CardTitle>
            <CardText>регистраций</CardText>

          </CardTextArea>
        </CardInfo>
        <CardInfo>
          <CardTextArea>
            <CardTitle>{new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(data.balance))}</CardTitle>
            <CardText>сумма депозитов</CardText>

          </CardTextArea>
        </CardInfo>
        <CardInfo>
          <CardTextArea>
            <CardTitle>{new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(data.bonus))}</CardTitle>
            <CardText>сумма бонусов</CardText>

          </CardTextArea>
        </CardInfo>
        <CardInfo>
          <CardTextArea>
            <CardTitle>{new Intl.NumberFormat('ru-RU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(data.discount))}</CardTitle>
            <CardText>сумма скидок</CardText>

          </CardTextArea>
        </CardInfo>
      </HeaderInfoWrap>)
}

export default HeaderInfo;

const StyledSelect = styled(Select)`
  /* За сегодня */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 34px;
  /* identical to box height, or 112% */
  align-items: center;

  /* Текст/#333333 текст|кнопки */
  color: #333333;
`

const HeaderInfoWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding: 16px;
  background: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
  border-radius: 5px; */
  /* gap: 60px; */
`

const CardInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 170px;
  height: 63px; */
`

const CardTextArea = styled.div`
  display: flex;
  flex-direction: column;
  /* Frame 195 */
  padding-top: 12px;
  padding-left: 17px;
  padding-right: 17px;
  width: 257px;
  height: 92px;

  /* Текст/#FFFFFF текст|иконки|кнопки */
  background: #FFFFFF;
  box-shadow: 0px 2px 15.2px rgba(0, 0, 0, 0.11);
  border-radius: 5px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
`

const CardTitle = styled.div`
  /* За сегодня */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 34px;
  /* identical to box height, or 112% */
  align-items: center;

  /* Текст/#333333 текст|кнопки */
  color: #333333;

`

const CardText = styled.div`
  width: 132px;
  height: 28px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  align-items: center;
  color: #8F8F8F;
`

const CardDesc = styled.div`
  top: 16px;
  right: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
/* identical to box height, or 200% */
  align-items: center;
  text-align: right;

  /* Текст/#8F8F8F текст */
  color: #8F8F8F;


`
