import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components'
import { IBookingObj } from '../../../types';
import { getNoun } from '../../../utils/getNoun';
import moment from 'moment';

const apiUrl = process.env.REACT_APP_API_URL;

interface PropsClientModal {
  selectedIds: any[]
  data: IBookingObj[] | undefined
  activeDialog: string
  modalVisibilityToggler: any
}

const getFetchDelete = (uri: string, method: string) => {
  return fetch(uri, {
    method: method,
    // credentials: 'include',
    headers: {
      'accept': 'application/json',
      'Authorization': `${localStorage.getItem('accessToken')}`,
      'Content-Type': 'application/json',
    }
  })
    .then(res => res.json())
    .catch(() => [])
}

const getFetchDublicate = (uri: string, obj: IBookingObj, method: string) => {
  console.log('uri', uri);
  const bodyNewBooking = {
    bookingStart: moment().valueOf(),
    prePaid: obj.prePaid,
    personName: obj.personName,
    personPhone: obj.personPhone,
    guestsCount: obj.guestsCount,
    hall_id: obj.hall_id,
    table_id: obj.table_id,
    comment: 'создан дублированием',
    state: 0,
  }
  return fetch(uri, {
    method: method,
    // credentials: 'include',
    body: JSON.stringify(bodyNewBooking),
    headers: {
      'accept': 'application/json',
      'Authorization': `${localStorage.getItem('accessToken')}`,
      'Content-Type': 'application/json',
    }
  })
    .then(res => res.json())
    .catch(() => [])
}

const DialogModal: React.FC<PropsClientModal> = ({ selectedIds, data, activeDialog, modalVisibilityToggler}) => {
  const getDelete = () => {
    Promise.all(
      selectedIds.map(i => getFetchDelete(`${apiUrl}/shops/1/booking/${i}`, 'DELETE'))
    )
      .then(result => {
        console.log('result', result);

      })
    
  }
  
  const getDublicate = async () => {
    const dataObjs = selectedIds.map((id: number) => {
      return data?.find(o => o.id === id)
    })
    console.log('dataObjs' , dataObjs);
    const promises = dataObjs.map(async (obj) => {
      if (obj) {
        // Ваши асинхронные действия с найденными объектами (например, вызов API)
        await getFetchDublicate(`${apiUrl}/shops/1/booking`, obj, 'POST');
      }
    });
    await Promise.all(promises);
  }

  const onSubmit = () => {
    if (activeDialog === 'delete') getDelete()
    if (activeDialog === 'dublicate') getDublicate()
    // modalVisibilityToggler('accept', null)
  }

  const handleCancel = (e: React.FormEvent) => {
    modalVisibilityToggler('cancel', null)
  };

  return (
    <Wrapper>
      <NavbarModalWrapper>
        <StyledForm onSubmit={onSubmit}>
          <FormItemContainer>
            {(activeDialog === 'delete') && (<label>{`Вы хотите удалить:   `}</label>)}
            {(activeDialog === 'dublicate') && (<label>{`Вы хотите дублировать:   `}</label>)}
            <label style={{marginLeft: '6px'}}>{getNoun(selectedIds.length, 'бронирование', 'бронирования', 'бронирований')} </label>
            
          </FormItemContainer>
          <StyledFooter>
            <StyledButtonClose onClick={handleCancel}>Отмена</StyledButtonClose>
            <StyledButtonSave type="submit"><span>Подтвердить</span></StyledButtonSave>
          </StyledFooter>
        </StyledForm>
      </NavbarModalWrapper>    
    </Wrapper>
   
  )
}

export default DialogModal;

const StyledButtonClose = styled.div`
  /* Закрыть */

  width: 59px;
  height: 28px;

  /* Текст */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 200% */
  display: flex;
  align-items: center;
  text-align: center;

  /* Текст/#8F8F8F текст */
  color: #8F8F8F;
  cursor: pointer;

`

const StyledButtonSave = styled.button`
    /* Frame 37 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 58px;
  gap: 10px;
  cursor: pointer;
  width: 195px;
  height: 40px;

  /* Текст/#A7388E текст|кнопки */
  background: #A7388E;
  border-radius: 5px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;

  & span {
        /* Сохранить */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    align-items: center;
    text-align: center;

    /* Текст/#FFFFFF текст|иконки|кнопки */
    color: #FFFFFF;
  }
`

const StyledFooter = styled.div`
  /* Frame 40 */
  display: flex;
  gap: 64px;
  flex-direction: row;
  padding-left: 50px;
  justify-content: space-between;
  height: 40px;
  margin-top: 16px;
  align-items: center;
  position: absolute;
  bottom: 10px;
  /* right: 10px; */
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
`
const Wrapper = styled.div`
  overflow: hidden;
  display: flex;
  position: fixed;
  flex-direction: row;
  justify-content: flex-start;
  /* min-width: 100%; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1105;
  background-color: #fff;
  /* box-sizing: border-box; */

/* Auto layout */
  padding: 30px 20px;
  
  width: 400px;
  height: 200px;
  /* Текст/#FFFFFF текст|иконки|кнопки */
  background: #F7F7F7;
  /* #D6D6D6 графики */
  /* border: 0.5px solid #D6D6D6; */
  /* box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.08); */
  border-radius: 5px;

  
`

const NavbarModalWrapper = styled.div`
  width: 560px;
  height: 46px;
  margin-bottom: 18px;
  gap: 20px;

`

const FormItemContainer = styled.div<{
  password?: boolean
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  & svg { 
    position: absolute;
    right: 17px;
    top: 15px;
    cursor: pointer;
  }

  ${({ password }) => password && css`
    display: flex;
    gap: 10px;
  `}

`

const Input = styled.input<{
  password?: boolean
  $hasError?: boolean;
}>`
  margin-left: 20px;
  position: relative;
  width: 400px;
  height: 44px;
  padding: 0 17px 0 18px;
  border-radius: 8px;
  color: #1F1E24;
  border: 1px solid ${props => props.$hasError ? 'red' : '#D6DCE9'}; 
  font-weight: 600;
  font-size: 13px;
  line-height: 26px;
  z-index: 0;

  ${({ password }) => password && css`
    width: 156px;
  `}

  &:focus {
    border: 1px solid #E65E20;
    border-color: #E65E20;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &:hover {
    border: 1px solid #E65E20;
    border-color: #E65E20;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  &:-webkit-autofill {
    -webkit-transition: 'background-color 9999s ease-out';
    -webkit-transition-delay: 9999s;
  }

  ::placeholder { 
    color: #A5A5A5;
  }

  :-ms-input-placeholder { 
    color: #A5A5A5;
  }

  ::-ms-input-placeholder { 
    color: #A5A5A5;
  }

  &:invalid {
    border: 1px solid #E65E20;
  } 
`

const Label = styled.label`
  font-weight: 600;
  width: 115px;
  font-size: 11px !important;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #000;
  margin-left: 20px;
  & span {
    color: #E65E20;
    margin-left: 4px;
  }
`
