import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import moment from 'moment';
import { Table, Select, Checkbox } from 'antd';
import type { TableColumnsType, GetProp, TableProps } from 'antd';
// import ClientModal from './components/ClientModal'
import { Icons } from '../../assets';
import Highlighter from 'react-highlight-words';
import PromoutersHeaderInfo from './components/PromoutersHeaderInfo';
import { columnsPromoutersTable } from './components/columns';

type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

moment().format();

const PromoutersPage = () => {
  const [openedSettingsPopup, setOpenedSettingsPopup] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([])
  const [sortedData, setSortedData] = useState<any[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedClient, setSelectedClient] = useState<any>(undefined)
  const [searchText, setSearchText] = useState('');
  const [selectValue, setSelectValue] = useState<number>(1)
  
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  useEffect(() => {
    const sorted = data.sort((a, b) => {
      const order = { 'request': 1, 'accepted': 2, 'decline': 3 };
      return (order[a.shopStatus] || 4) - (order[b.shopStatus] || 4);
    });
    setSortedData(sorted)
    // setSortedData(promoutersData)
  }, [data])

  useEffect(() => {
    if (loading ) {
      const clientsUrl = `${process.env.REACT_APP_API_URL}/shops/1/promo/promouters`
      Promise.all([
        fetch(clientsUrl, {
          // credentials: 'include',
          headers: {
            'accept': 'application/json',
            'Authorization': `${localStorage.getItem('accessToken')}`,
            'Content-Type': 'application/json',

          }
        })
          .then(res => res.json())
          .catch(() => []),
      ])
        .then(([data]) => {
          
          if (data && data.rows && Array.isArray(data.rows) && data.rows.length > 0) {
            setData(data.rows) 
          } else setData([])

        })
      setLoading(false);
      setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: 200,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
    }
  }, [loading, tableParams])

  useEffect(() => {setLoading(true)}, [])

  const getColumnSearchProps = dataIndex => ({
  // ... (остальные свойства)
    render: text =>
      searchText ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  
  const optionsSelect = [
    { value: 1, label: 'Заблокировать / активировать' },
    { value: 2, label: 'Удалить' },
    { value: 3, label: 'Разрешить' },
    { value: 4, label: 'Отказать' },
    { value: 5, label: 'пометить в ожидание' },
  ]

  const onChangeSelect = (value) => {
    setSelectValue(value)
  }

  const renderTableFooter = () => {
    return (
    <FooterWrap>
      <ActionWrap>
        <StyledSelect options={optionsSelect} value={selectValue} onChange={onChangeSelect}/>
        <StyledActionButton><span>Применить</span></StyledActionButton>
        <StyledCheckbox><span>для всех</span></StyledCheckbox>
      </ActionWrap>
      {/* Отфильтровано: {sortedData.length}/{data.length} |
      Выделено: {selectedRowKeys.length} */}
    </FooterWrap>
    );
  };
  
  const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
  };

  const overlayOnClick = () => {
    setOpenedSettingsPopup(false)
  }

  const handlerClickId = (id) => {
    const findClient = data.find(o => o.id === id)
    if (findClient) {
      setSelectedClient(findClient)
      setOpenedSettingsPopup(true)
    }
    
  }
  
  const columns: TableColumnsType = columnsPromoutersTable(searchText, getColumnSearchProps, handlerClickId);
  
  const components = {
    body: {
      row: ({ children, props, ...restProps }) => {
        const filtered = sortedData.find(o=>o.id === restProps['data-row-key'])
        const highlight = filtered && filtered.shopStatus === 'request';
        return (
          <tr {...restProps} className={highlight ? 'ant-table-row ant-table-row-level-0 highlight-row' : 'ant-table-row ant-table-row-level-0'}>
            {children}
          </tr>
        );
      },
    },
  }

  return (
    <Wrapper>
      <Header>
        <HeaderTitle>Промоутеры</HeaderTitle>
      </Header>
      <PromoutersHeaderInfo />
      <SearchWrap>
        <SearchInput
          placeholder='Поиск...'
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
        <ButtonBlok>
          <StyledAddButton><Icons.AddGreyPlusInCircle /><span>Добавить</span></StyledAddButton>
        </ButtonBlok>
      </SearchWrap>
      <TableWrapper>
        <Table
          size='small'
          rowSelection={rowSelection}
          // rowClassName={rowClassName}
          columns={columns}
          rowKey={(rec) => rec.id}
          dataSource={sortedData}
          onChange={handleTableChange}
          pagination={false}
          footer={renderTableFooter}
          components={components}
        />

      </TableWrapper>
      <Overlay
        onClick={overlayOnClick}
        display={(openedSettingsPopup) ? 'block' : 'none'}
      />
      {/* {openedSettingsPopup && (
        <ClientModal selectedClient={selectedClient} setOpeningSettingsPopup={setOpenedSettingsPopup} setLoad={setLoading} />
      )} */}
    </Wrapper>
   
  )
}

export default PromoutersPage;

const StyledAddButton = styled.div`
  /* Frame 193 */

  /* Auto layout */
  display: flex;
  flex-direction: raw;
  align-items: center;
  padding: 3px 10px;
  gap: 10px;
    cursor: pointer;
  width: 128px;
  height: 34px;

  /* Текст/#FFFFFF текст|иконки|кнопки */
  background: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
  border-radius: 5px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  color: #8F8F8F;
  & svg {
    /* image 39 (Traced) */
    width: 20px;
    height: 20px;

    /* Текст/#8F8F8F текст */
    color: #8F8F8F;

  }
`

const FooterWrap = styled.div`
  display: flex;
  flex-direction: raw;
`

const ActionWrap = styled.div`
  display: flex;
  flex-direction: raw;
  justify-content: flex-start;
  gap: 10px;
`

const StyledSelect = styled(Select)`
  /* Frame 148 */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 5px 10px; */
  gap: 10px;

  width: 175px;
  height: 38px;

`
const StyledActionButton = styled.div`
  /* Frame 37 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  gap: 10px;

  width: 98px;
  height: 38px;

  /* Текст/#A7388E текст|кнопки */
  background: #A7388E;
  border-radius: 5px;

  cursor: pointer;
  & span {
    /* Применить */

    width: 78px;
    height: 28px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    display: flex;
    align-items: center;

    /* #F7F7F7 основной фон */
    color: #F7F7F7;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

  }
`
const StyledCheckbox = styled(Checkbox)`
  display: flex;
  align-items: center;
`

const SearchWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`
const SearchInput = styled.input`
  width: 1227px;
  padding-left: 20px;
  /* Frame 91 */

  height: 33px;

  /* Текст/#FFFFFF текст|иконки|кнопки */
  background: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.08);
  border-radius: 5px;

  border: none;

`
const ButtonBlok = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* width: 160px; */
`
const TableWrapper = styled.div`
  margin-top: 10px;

  .ant-table-thead {
    height: 50px; /* Вы можете установить желаемую высоту */
    line-height: 50px; /* Установите такое же значение, как и высота, для вертикального центрирования содержимого */
    /* Rectangle 91 */

    background: rgba(148, 129, 205, 0.25);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px 5px 0px 0px;
  }
  .ant-table-thead>tr>th::before {
    content: none !important;
  }
  .ant-table-cell {
    border-right: none !important; 
    border-left: none !important;/* Убираем вертикальные границы между ячейками */
  }

  .ant-table-row .ant-table-cell {
    height: 46px !important; 
    /* 79991233232 */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    align-items: center;
    /* Текст/#333333 текст|кнопки */
    color: #333333;

    & svg {
      margin-top: 9px;
    }
  }

  & td.leftAlign {
    text-align: left !important;
    padding-left: 5px !important;
  }

  & th.headerCenter {
    text-align: center !important;
  }
`

const HeaderTitle = styled.div`
    /* Клиенты */
  margin-top: 38px;
  width: 110px;
  height: 28px;
  /* Заголовок */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  /* identical to box height, or 112% */
  align-items: center;

  /* Текст/#333333 текст|кнопки */
  color: #333333;


`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* min-width: 100%; */
  width: 100%;
  min-height: 90%;
  top: 0px;
  gap: 16px;
  background-color: #F7F7F7;
  
  .ant-table-row {
    height: 14px !important; 
    padding: 2px !important;/* Установите желаемую высоту строки */
  }

  .ant-table-cell {
    height: 14px !important; 
    padding: 2px !important;/* Установите желаемую высоту строки */
  }

  .ant-table-tbody>tr>td{
    /* background-color: #ffffff; */
    font-family: Inter;
    border: 1px solid #EFEFEF;
    color: #333333;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    padding: 2px;
    height: 12px;

  }

  .ant-table-summary {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: left;

  }

  .ant-table-thead > tr > th {
    background-color: #9481CD40;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 600;
    line-height: 13.2px;
    text-align: left;

  }
  .highlight-row {
    background-color: #F6F5EB !important; /* Желтый цвет для выделения */
  }
`

const Header = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  top: 0px;
  margin-bottom: 20px;
  /* gap: 30px; */
`

const Overlay = styled.div<{
  display: string
}>`
  display: ${({ display }) => display};
  position: absolute;
  top: -1px;
  left: -1px;
  z-index: 1000;
  width: 100vw;
  height: 150vh;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(1px);
  transition: width .3s;

`
