import { CSSProperties, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components'
import moment from 'moment';
import dayjs from 'dayjs';
import { PuffLoader } from 'react-spinners';
import { DatePicker, Select } from 'antd'
import { useApplication } from '../../context/ApplicationContext';

import { Icons } from '../../assets';

import { LineChart } from '../rkeeper/components/LineChart';
import PromoReportTable from './components/PromoReportTable';

import { dataPromo } from './mock';

const { RangePicker } = DatePicker;

moment().format();

const selectTablesValues = [
  {value: 'viruchka', label: 'Выручка'},
  {value: 'Zarabotok', label: 'Заработок'},
]
const PromoReportPage = () => {
  const { dateRange, setDateRange } = useApplication()
  const [selectValue, setSelectValue] = useState<string>(selectTablesValues[0].value)
  const [selectValuePromo, setSelectValuePromo] = useState<string>(dataPromo[0].name);
  const [dataChart, setDataChart] = useState<any | null>(null);
  const [dataTable, setDataTable] = useState<any | null>(null);

  useEffect(() => {
    const dataFind: any[] = dataPromo.filter(i => i.name === selectValuePromo)
    setDataChart(dataFind[0])
  }, [selectValuePromo])

  useEffect(() => {

  }, [selectValue])

  useEffect(() => {
    const dataFind = dataPromo.map(i => {
      return i
    })
    setDataTable(dataFind)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPromo])

  const from = useMemo(() => {
    return dateRange && dateRange.from ? dateRange.from : moment().add(-1, 'month').utc().valueOf()
  } , [dateRange])
  const to = useMemo(() => dateRange && dateRange.to ? dateRange.to : moment().utc().valueOf(), [dateRange])

  const cssOverride: CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
  }

  const onDateChange = (date: any, dateString: [string, string]) => {
    
    if (date) {
      setDateRange({
        from: dayjs(dateString[0]).add(1, 'day').valueOf(),
        to: dayjs(dateString[1]).add(1, 'day').valueOf(),
      })
      
      
    } else {
      // setHistoryDateRange([])
    }
  }

  const handleChange = (value: any) => {
    setSelectValue(value)
  };

  const handleChangePromo = (value: any) => {
    setSelectValuePromo(value)
  };

  return (
    <Wrapper>
      <Header>
        <StyledHeadTitle>Отчет по промоутерам</StyledHeadTitle>
        <StyledCaledarWrapper>
          <StyledCalendarMemo>
            <Icons.IconSlideTrendFill />
            Сравнение периодов
          </StyledCalendarMemo>
          <StyledRangePicker
            suffixIcon={<Icons.IconMainCalendar />}
            value={[dayjs(from), dayjs(to)]}
            format={['YYYY-MM-DD', 'YYYY-MM-DD']}
            onChange={onDateChange}
          />
        </StyledCaledarWrapper>
      </Header>
      {false ? (
        <PuffLoader color="#E65E20" cssOverride={cssOverride} />
      ) : (
        <div>
          <ChartsWrapper>
              <StyledSelect 
              value={selectValue}
              onChange={handleChange} 
              options={selectTablesValues}
            />
            <StyledSelect 
              value={selectValuePromo}
              onChange={handleChangePromo} 
                mode="multiple"
                style={{marginLeft: '200px'}}
              options={dataPromo.map(i => ({
                value: i.name,
                label: i.name,
              }))}
            />
        
            {dataChart && (<LineChart dataSource={dataChart} />)}
          </ChartsWrapper>
          <PromoReportTable
            dataValues={dataTable}
          />
          
        </div>
      )}
    </Wrapper>)
}

export default PromoReportPage;

const StyledSelect = styled(Select)`
  /* Frame 148 */
  position: absolute;
  box-sizing: border-box;
  margin-top: -10px;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 5px 10px; */
  gap: 10px;

  width: 175px;
  height: 38px;
`

const Wrapper = styled.div`
  min-width: 100%;
  height: 80%h;
  background-color: #F7F7F7;
  padding: 16px 29px 16px 0px;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

const StyledRangePicker = styled(RangePicker)`
  width: 250px;
  color: #ffffff;
  
`

const StyledHeadTitle = styled.label`
  font-family: 'Inter';
  font-size: 25px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #333333;
`

const StyledCaledarWrapper = styled.div`
  display: flex;
  gap: 10px;
  color: #ffffff;
  
  & svg {
    width: 20px;
    height: 20px;
  }
  .ant-picker {
    background-color: #A7388E;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
  }

  .ant-picker .ant-picker-input,
  .ant-picker .ant-picker-range,
  .ant-picker .ant-picker-input-active .ant-picker-input-placeholder {
    display: flex;
    flex-direction: row-reverse;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #ffffff;

  }

  .ant-picker .ant-picker-input .ant-picker-input-active {
    color: #ffffff !important;
  }

  .ant-picker .ant-picker-input .ant-picker-input-placeholder {
    color: #ffffff !important;
  }

  .ant-picker .ant-picker-input span.ant-picker-suffix,
  .ant-picker.ant-picker-range span.ant-picker-suffix {
    order: -1;
    margin-left: 0;
    margin-right: 10px; /* Для отступа иконки от текста */
    color: #ffffff;
  }

  .ant-picker.ant-picker-range.ant-picker-range-separator {
    color: #ffffff !important;
    /* display: none; */
  }

  .ant-picker-range-separator > span {
    color: #ffffff !important; /* Замените #ваш_цвет на нужный код цвета */
  }
  
  .ant-picker-clear {
    color: #ffffff !important; /* Замените #ваш_цвет на нужный код цвета */
  }
  
  .ant-picker .ant-picker-clear {
    position: absolute;
    right: 217px;
    /* color: #ffffff !important; */
  }


`

const StyledCalendarMemo = styled.div`
  display: flex;
  width: 220px;
  height: 40px;
  gap: 10px;
  background-color: #E9E9E9;
  border-radius: 5px 0px 0px 0px;
  opacity: 0px;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #333333;

`

const ChartsWrapper = styled.div`
  height: 470px;
  width: 100%;
  margin-bottom: 60px;
  padding: 30px;
  background-color: #fff;
  border-radius: 5px;
`
